
import { useEffect, useState } from "react";
import { apiUrl } from "environmentConfig";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


const ResetPasswordPage = (props) => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const resetEmail = useQuery().get("email");
  const resetToken = useQuery().get("token");

  const isUpdatePassword = () => {
    return resetEmail !== null && resetEmail.length !== 0 &&
      resetToken !== null && resetToken.length !== 0
  }

  const recoverPassword = async () => {

    const response = isUpdatePassword() 
    ? await axios.post(apiUrl + "/auth/confirmResetToken", { email: resetEmail,token: resetToken,password: password }, { validateStatus: false })
    : await axios.post(apiUrl + "/auth/resetPassword", { email }, { validateStatus: false });

    console.log(response);

    const ok = response.data.statusCode === 201 ? true : false;

    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: ok ? "success" : "error",
      title: response.data.message,
      confirmButtonText: "Continuar",
      width: "500px",
    })
      .then((result) => {
        if (ok) {
          window.location.replace("/")
        }
      });

  };


  const renderEmailForm = () => {
    return (
      <div>
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-alt"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Endereço de Email"
          />
        </div>


        <div className="form-group">
          <button
            type="button"
            className="btn btn-block btn-hero-primary"
            onClick={() => {
              recoverPassword();
            }}>
            <i className="fa fa-fw fa-envelope mr-1"></i>{" "}
            Confirmar
          </button>

          <Link to={`/`} className="btn btn-block btn-hero-primary">
            <i className="fa fa-fw fa-reply mr-1"></i>{" "}
            Cancelar
          </Link>
        </div>
      </div>
    );
  }

  const renderPasswordForm = () => {
    return (
      <div>
        <div className="form-group">
          <input
            type="password"
            className="form-control form-control-alt"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Nova palavra-passe"
          />
        </div>


        <div className="form-group">
          <button
            type="button"
            className="btn btn-block btn-hero-primary"
            onClick={() => {
              recoverPassword();
            }}>
            <i className="fa fa-fw fa-check mr-1"></i>{" "}
            Confirmar
          </button>

          <Link to={`/`} className="btn btn-block btn-hero-primary">
            <i className="fa fa-fw fa-reply mr-1"></i>{" "}
            Cancelar
          </Link>
        </div>
      </div>
    );
  }

  return (
    <main id="main-container">
      <div className="row no-gutters justify-content-center bg-body-dark">
        <div className="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
          <div
            className="block block-rounded block-transparent block-fx-pop w-100 mb-0 overflow-hidden bg-image"
            style={{
              backgroundImage: `url('/assets/media/photos/photo19@2x.jpg')`,
            }}
          >
            <div className="row no-gutters">
              <div className="col-md-6 order-md-1 bg-white">
                <div className="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
                  <div className="mb-2 text-center">
                    <a className="link-fx font-w700 font-size-h1" href="/">
                      <span className="text-dark">Flow</span>
                      <span className="text-primary">Consent</span>
                    </a>
                    <p className="text-uppercase font-w700 font-size-sm text-muted">
                      Recuperar password
                    </p>
                  </div>

                  {isUpdatePassword() ? renderPasswordForm() : renderEmailForm()}

                </div>

                {/* <div className="row text-center">
                  <div className="col-12">
                    <img
                      src={`${process.env.PUBLIC_URL}assets/media/logos/emptytroubles.png`}
                      alt=""
                      width="100px"
                    />
                  </div>
                </div> */}
              </div>

              <div className="col-md-6 order-md-0 bg-primary-dark-op d-flex align-items-center">
                <div className="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
                  <div className="media">
                    <div className="media-body">
                      <h2 className="text-white font-w600 mb-1">
                        Plataforma para Pedidos de Aprovação
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPasswordPage;
