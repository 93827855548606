import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export const getApproval = createAsyncThunk(
  "approvals/getApproval",
  async (approvalId) => {
    const response = await axios.get(`/approval/${approvalId}`);
    const data = await response.data.approval;

    return data === undefined ? null : data;
  }
);

export const importApprovalTemplate = createAsyncThunk(
  "approvals/importApprovalTemplate",
  async (approvalTemplateId) => {
    const response = await axios.get(
      `/approval-template/${approvalTemplateId}`
    );
    const data = await response.data.approvalTemplate;

    return data === undefined ? null : data;
  }
);

export const saveApproval = createAsyncThunk(
  "approvals/saveApproval",
  async (approvalData) => {
    try {
      console.log(approvalData);
      if (!approvalData.id) {
        const response = await axios.post(`/approval`, approvalData);
        const data = await response.data;

        return data;
      } else {
        const response = await axios.put(
          `/approval/${approvalData.id}`,
          approvalData
        );
        const data = await response.data;

        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const cancelApproval = createAsyncThunk("approvals/cancelApproval",
  async (id) => {
    return await axios.post(`/approval/${id}/cancel`);
  }
)

export const deleteApproval = createAsyncThunk("approvals/deleteApproval",
    async (id) => {
        const resposnse = await axios.delete(`/approval/${id}`);
    }
)

export const publishApproval = createAsyncThunk(
  "approvals/publishApproval",
  async (approvalId) => {
    const response = await axios.put(`/approval/${approvalId}/publish`);
    const data = await response.data.approval;

    if (response.data.errors.length > 0) {
      const errors = response.data.errors
        .map((x) => {
          return x.message;
        })
        .join("<br/>");
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: errors,
        width: "800px",
      });
    }

    return data === undefined ? null : data;
  }
);

export const sendStepResponse = createAsyncThunk(
  "approvals/sendStepResponse",
  async ({ stepId, responseValue, responseText }) => {
    const response = await axios.put(`/approval-step/${stepId}/response`, {
      response: responseValue,
      responseText,
    });
  }
);

export const acknowledgeApproval = createAsyncThunk(
  "approvals/acknowledgeApproval",
  async (approvalId) => {
    const response = await axios.put(`/approval/${approvalId}/acknowledge`);
  }
);

const approvalSlice = createSlice({
  name: "approval",
  initialState: null,
  reducers: {
    resetApproval: () => null,
    newApproval: {
      reducer: (state, action) => action.payload,
      prepare: (event) => ({
        payload: {
          title: "",
          description: "",
          attachments: [],
          steps: [],
          fields: [],
          connoisseurs: [],
        },
      }),
    },
  },
  extraReducers: {
    [getApproval.fulfilled]: (state, action) => action.payload,
    [getApproval.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [importApprovalTemplate.fulfilled]: (state, action) => {
      let connoisseurs = action.payload.templateConnoisseurs.map((x) => {
        return { user: x.user };
      });

      let fields = action.payload.templateFields.map((x) => {
        return {
          name: x.name,
          value: "",
          index: x.index
        };
      });

      let steps = action.payload.templateSteps.map((x) => {
        return x
      });

      let title = action.payload.templateTitle ?? "";

      return {
        title: title,
        description: "",
        attachments: [],
        steps: steps,
        fields: fields,
        connoisseurs: connoisseurs,
      };
    },
    [importApprovalTemplate.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [saveApproval.fulfilled]: (state, action) => action.payload,
    [saveApproval.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [publishApproval.fulfilled]: (state, action) => action.payload,
    [publishApproval.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },

    [cancelApproval.fulfilled]: (state,action) => state = action.payload.data,
    [cancelApproval.rejected]: (state,action) => error(action.error.message),
    [deleteApproval.fulfilled]: (state, action) => { },
    [deleteApproval.rejected]: (state, action) => error(action.error.message),
  },
});

export const { newApproval, resetApproval } = approvalSlice.actions;

export default approvalSlice.reducer;


const error = (message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
      icon: "error",
      title: "Ocorreram um ou mais erros",
      html: message,
      width: "800px",
  })
}