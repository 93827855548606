import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import history from "./@history";

import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import Footer from "./layout/Footer";

import authHeader from "utils/auth-header";
import { apiUrl } from "environmentConfig";
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from "@azure/msal-react";
import {generateRoutes} from "./config/routes";
import {interceptPermissionsChanged} from "./config/interceptor";


function App() {
  axios.defaults.baseURL = apiUrl;

  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.defaults.headers.common = authHeader();

  const user = useSelector((root) => {
    return root.userReducer;
  });
  const routes = generateRoutes(user);


  const dispatch = useDispatch();
  const { instance } = useMsal();
  interceptPermissionsChanged.getInstance(dispatch,instance)

  return (
    <div
      id="page-container"
      className="sidebar-o sidebar-dark side-scroll page-header-fixed main-content-narrow"
    >
      <Sidebar routes={routes}/>

      <Header />
      <main id="main-container">
        <Switch history={history}>
          {routes.map((router, index) => {
            if (router.exact) {
              return (
                <Route
                  key={`router${index}`}
                  path={router.path}
                  exact
                  component={router.component}
                />
              );
            }

            return (
              <Route
                key={`router${index}`}
                path={router.path}
                component={router.component}
              />
            );
          })}
          <Redirect to='/404' />
        </Switch>
      </main>

      <Footer />
    </div>
  );
}

export default withRouter(App);
