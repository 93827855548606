import React, { useEffect, useState } from "react";
import _ from "@lodash";
import PeoplePicker from "../components/PeoplePicker";
import { SpinButton } from "@fluentui/react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { animateScroll } from "react-scroll";
import { useParams } from "react-router";
import { Link, Redirect } from "react-router-dom";
import withReducer from "store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useForm, useDeepCompareEffect } from "hooks";
import sizeToHuman from "utils/sizeToHuman";
import * as pathToRegexp from "path-to-regexp";
import {
  deleteApprovalTemplate,
  getApprovalTemplate,
  newApprovalTemplate,
  resetApprovalTemplate,
  saveApprovalTemplate,
} from "./store/approvalTemplateSlice";

const ApprovalTemplatesNewPage = (props) => {
  const dispatch = useDispatch();
  const approvalTemplate = useSelector(({ approvalTemplatesModule }) => {
    return approvalTemplatesModule.approvalTemplateReducer;
  });

  const user = useSelector((root) => {
    return root.userReducer;
  });
  

  const [noApprovalTemplate, setNoApprovalTemplate] = useState(false);
  const { form, handleChange, setForm, setInForm } = useForm(null);
  const routeParams = useParams();

  const changePageToView = (id) => {
    const toPath = pathToRegexp.compile('/approvals/templates/:templateId');
		const newPath = toPath({
			...routeParams,
			templateId: "" + id
		});
		if (newPath !== props.match.url) {
			props.history.push(newPath);
		}
  }

  useDeepCompareEffect(() => {
    function updateApprovalTemplateState() {
      const { templateId } = routeParams;
      if (templateId === "new") {
        dispatch(newApprovalTemplate());
      } else {
        dispatch(getApprovalTemplate(routeParams.templateId)).then((action) => {
          if (!action.payload) {
            setNoApprovalTemplate(true);
          }
        });
      }
    }

    updateApprovalTemplateState();
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (
      (approvalTemplate && !form) ||
      (approvalTemplate && form && approvalTemplate.id !== form.id)
    ) {
      setForm(approvalTemplate);
    }
  }, [form, approvalTemplate, setForm]);

  useEffect(() => {
    return () => {
      dispatch(resetApprovalTemplate());
      setNoApprovalTemplate(false);
    };
  }, [dispatch]);

  function canBeSubmitted() {
    return form.templateTitle.length > 0 && !_.isEqual(approvalTemplate, form);
  }


  const handleSelectedSharedUsers = (items) => {
    setForm({ ...form, sharedUsers: items });
  };

  const handleSelectedConnoisseurs = (items) => {
    setForm({ ...form, templateConnoisseurs: items });
  };

  /* Start Steps Management */
  const addStep = () => {
    let templateSteps = form.templateSteps.map((step) => {
      return { ...step };
    });

    templateSteps.push({
      index: 0,
      name: "",
      approvers: [],
      approversOptional: [],
      neededApprovers: 0,
    });

    templateSteps = templateSteps.map((step, index) => {
      return { ...step, index: index };
    });

    setForm({ ...form, templateSteps: templateSteps });

    animateScroll.scrollToBottom();
  };

  const removeStep = (step, index) => {
    let templateSteps = [...form.templateSteps];

    templateSteps = templateSteps
      .slice(0, index)
      .concat(templateSteps.slice(index + 1));

    templateSteps = templateSteps.map((step, index) => {
      return { ...step, index: index };
    });

    setForm({ ...form, templateSteps: templateSteps });
  };

  const handleStepNeededApprovers = (e, index) => {
    let templateSteps = form.templateSteps.map((step, i) => {
      if (index === i) {
        if (Number(e) > 100 || Number(e) < 0 || isNaN(+e)) {
          return { ...step, neededApprovers: 0 };
        } else {
          return { ...step, neededApprovers: e };
        }
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, templateSteps: templateSteps });
  };

  const handleStepNameChange = (e, index) => {
    let templateSteps = form.templateSteps.map((step, i) => {
      if (index === i) {
        return { ...step, name: e.target.value };
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, templateSteps: templateSteps });
  };

  /* // End Steps Management // */

  /* Start Approvers Management */

  const handleSelectedMandatoryApprovers = (index, items) => {
    let templateSteps = form.templateSteps.map((step, i) => {
      if (index === i) {
        let approvers = step.approvers.filter(
          (x) => x.mandatory === "0"
        );
        Array.prototype.push.apply(
          approvers,
          items.map((x) => ({ ...x, mandatory: "1" }))
        );
        return { ...step, approvers };
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, templateSteps: templateSteps });
  };

  const handleSelectedApprovers = (index, items) => {
    let templateSteps = form.templateSteps.map((step, i) => {
      if (index === i) {
        let approvers = step.approvers.filter((x) => x.mandatory === "1");
        Array.prototype.push.apply(
          approvers,
          items.map((x) => ({ ...x, mandatory: "0" }))
        );
        return { ...step, approvers };
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, templateSteps: templateSteps });
  };
  /* // End Approvers Management */

  /* Start Custom Fields */
  const addCustomField = async () => {
    const { value: fieldName } = await Swal.fire({
      title: "Introduza o nome do campo",
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Tem de indicar um nome!";
        }
      },
    });

    if (fieldName) {
      let templateFields = [...form.templateFields];

      templateFields.push({
        name: fieldName,
        index: 0,
      });

      templateFields = templateFields.map((field, index) => {
        return { ...field, index: index };
      });

      setForm({ ...form, templateFields: templateFields });
    }
  };

  const editCustomField = async (field, index) => {
    const { value: fieldName } = await Swal.fire({
      title: "Introduza o nome do campo",
      input: "text",
      inputValue: field.name,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Tem de indicar um nome!";
        }
      },
    });

    if (fieldName) {
      let templateFields = form.templateFields.map((field, i) => {
        if (index === i) {
          return { ...field, name: fieldName };
        } else {
          return { ...field };
        }
      });

      setForm({ ...form, templateFields: templateFields });
    }
  };

  const removeCustomField = (field, index) => {
    let templateFields = [...form.templateFields];

    templateFields = templateFields
      .slice(0, index)
      .concat(templateFields.slice(index + 1));

    templateFields = templateFields.map((field, index) => {
      return { ...field, index: index };
    });

    setForm({ ...form, templateFields: templateFields });
  };

  /* // End Custom Fields // */


  const isObtainingData = () => {
    return (
      (!approvalTemplate ||(approvalTemplate && routeParams.templateId != approvalTemplate.id)) 
      && routeParams.templateId !== "new"
      ) || !form
  }

  /* Save Button */
  const saveButtonClick = () => {
    dispatch(saveApprovalTemplate(form))
    .then((action) => {
      if (action.payload) {
        setForm(action.payload);
        changePageToView(action.payload.id);
      }
    });
  }
  /* Use Model Button */
  const useModelButtonClick = () => {
    props.history.push(`/approvals/new/template/${routeParams.templateId}`);
  }
  /* delete model button */
  const deleteModelButtonClick = () => {
    dispatch(deleteApprovalTemplate(routeParams.templateId)).then(()=>{
      props.history.push('/approvals/templates/')
    });
  }

  const isOwner = () => {
    return approvalTemplate.user.id === user.user.id; 
  }


  /* Render */
  if ( noApprovalTemplate || (approvalTemplate && !isOwner() && routeParams.templateId !== "new") ) {
    return <Redirect to={"/404"} />;
  }



  if (isObtainingData()) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn sticky-div">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              {form.templateTitle ? form.templateTitle : "Novo Modelo"}
            </h1>
            <button style={{ display: canBeSubmitted() || routeParams.templateId == 'new' ? 'none' : 'inline'}}
              onClick={deleteModelButtonClick} className="btn btn-alt-danger mr-2">Apagar modelo</button>

            <button style={{ display: canBeSubmitted() || routeParams.templateId == 'new' ? 'none' : 'inline'}} 
              disabled={canBeSubmitted() || routeParams.templateId == 'new'}
              onClick={useModelButtonClick} className="btn btn-alt-primary mr-2">Usar modelo</button>
            
            <button style={{display: !canBeSubmitted() ? 'none' : "inline"}} disabled={!canBeSubmitted()} className="btn btn-primary" 
              onClick={saveButtonClick}>
              Guardar
            </button>

          </div>
        </div>
      </div>
      <div className="content animated fadeIn">
        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação sobre o Modelo</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Informação que permite identificar este modelo de aprovação
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <div className="form-group">
                  <label>Título</label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, templateTitle: e.target.value })
                    }
                    value={form.templateTitle}
                  />
                </div>
                <div className="form-group">
                  <label>Modelo é Público</label>
                  <input
                    type="checkbox"
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, public: !form.public })
                    }
                    checked={form.public}
                  />
                </div>

                <div className="form-group" style={{ display: form.public ? 'none' : 'inline'}}>
                  <label>Compartilhar com</label>
                  <PeoplePicker
                    handleSelectedItems={(items) => {
                      handleSelectedSharedUsers(items);
                    }}
                    selectedItems={form.sharedUsers}
                    exclude={[user]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação Adicional</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Adicione campos de preenchimento adicional que sejam pertinentes para os
                  pedidos com base neste modelo
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                {form.templateFields.map((field, index) => {
                  return (
                    <div key={`field${index}`} className="form-group">
                      <label>{field.name}</label>
                      <div className="button-group">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => editCustomField(field, index)}
                        >
                          <i className="fa fa-fw fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(e) => removeCustomField(field, index)}
                        >
                          <i className="fa fa-fw fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="btn btn-success btn-sm mr-1 mb-3"
                  onClick={(e) => {
                    addCustomField();
                  }}
                >
                  <i className="fa fa-fw fa-plus mr-1"></i> Adicionar Campo
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Conhecimento do Pedido</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Mencione as pessoas que devem ter conhecimento do pedido de
                  aprovação resultante deste modelo, mas que não tem ação na tomada de decisão
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <PeoplePicker
                  handleSelectedItems={(items) => {
                    handleSelectedConnoisseurs(items);
                  }}
                  selectedItems={form.templateConnoisseurs}
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className="content-heading m-0">Fluxo de Aprovação</h2>
        <div className="row push">
          <div className="col-12">
            <ul className="timeline">
              {form.templateSteps.map((step, index) => {
                return (
                  <li key={`step${index}`} className="timeline-event">
                    <div className="timeline-event-icon bg-gray-dark">
                      <i className="far fa-check"></i>
                    </div>
                    <div className="timeline-event-block block block-rounded">
                      <div className="block-header block-header-default">
                        <h3 className="block-title">
                          <input
                            key={`stepName${index}`}
                            className="form-control"
                            placeholder={`Etapa ${step.index + 1}`}
                            onChange={(e) => {
                              handleStepNameChange(e, index);
                            }}
                            value={step.name}
                          />
                        </h3>
                        <div className="block-options">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => removeStep(step, index)}
                          >
                            <i className="fa fa-fw fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <div className="block-content pb-5">
                        <div className="row">
                          <div className="form-group col-6">
                            <label>Aprovadores obrigatórios</label>
                            <PeoplePicker
                              selectedItems={step.approvers.filter(
                                (x) => x.mandatory === "1"
                              )}
                              index={index}
                              handleSelectedItems={(items) => {
                                handleSelectedMandatoryApprovers(index, items);
                              }}
                              exclude={step.approvers.filter(
                                (x) => x.mandatory === "0"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-6">
                            <label>Aprovadores</label>
                            <PeoplePicker
                              selectedItems={step.approvers.filter(
                                (x) => x.mandatory === "0"
                              )}
                              handleSelectedItems={(items) => {
                                handleSelectedApprovers(index, items);
                              }}
                              exclude={step.approvers.filter(
                                (x) => x.mandatory === "1"
                              )}
                            />
                          </div>
                          <div className="form-group col-6">
                            <label>Aprovadores necessários</label>
                            <SpinButton
                              min={0}
                              max={step.neededApprovers}
                              value={step.neededApprovers}
                              onValidate={(value) =>
                                handleStepNeededApprovers(
                                  isNaN(value) ? 0 : parseInt(value),
                                  index
                                )
                              }
                              onIncrement={() =>
                                handleStepNeededApprovers(
                                  step.neededApprovers + 1,
                                  index
                                )
                              }
                              onDecrement={() =>
                                handleStepNeededApprovers(
                                  step.neededApprovers - 1,
                                  index
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}

              <li className="timeline-event">
                <button
                  type="button"
                  className="btn btn-hero-success mr-1 mb-3"
                  onClick={(e) => {
                    addStep();
                  }}
                >
                  <i className="fa fa-fw fa-plus mr-1"></i> Adicionar Etapa
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      )
    </React.Fragment>
  );
};

export default withReducer(
  "approvalTemplatesModule",
  reducer
)(ApprovalTemplatesNewPage);
