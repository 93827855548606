import React, { useState, useEffect } from 'react'

function Table(props) {

    const [loading, setLoading] = [props.loading?.loading, props.loading?.setLoading];
    const [data, setData] = [props.data?.data, props.data?.setData];
    const [search, setSearch] = [props.search?.search, props.search?.setSearch];

    const serachLabel = props.serachLabel ?? "Procure...";


    const renderTableBody = () => {
        if (loading) {
            return (
                <React.Fragment>
                    <div className="bg-body-light animated fadeIn">
                        <div className="content content-full">
                            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                                <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                                    <i className="fa fa-circle-notch fa-spin text-primary"></i>A
                                    obter dados do servidor...
                                </h1>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else if (!data || (data && data.length === 0)) {
            return (
                <React.Fragment>
                    <td colSpan={5} className="p-0">
                        <div className="bg-body-light animated fadeIn">
                            <div className="content content-full">
                                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                                    <h4 className="flex-sm-fill font-size-h4 font-w400 mt-2 mb-0 mb-sm-2">
                                        Sem resultados
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </td>
                </React.Fragment>
            );
        }
        else {

            return props.renderTableBodyData() ??
                (<React.Fragment>
                    <div className="bg-body-light animated fadeIn">
                        <div className="content content-full">
                            <h2>please define a render method</h2>
                        </div>
                    </div>
                </React.Fragment>)
        }
    }

    return (
        <div>
            {
                props.search &&
            
            <div className="form-group row items-push mb-0">
                <div className="col-sm-6 col-xl-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text bg-white">
                                <i className="fa fa-search"></i>
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control border-left-0"
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            value={search}
                            placeholder={serachLabel}
                        />
                    </div>
                </div>
            </div>
            }

            <div className="block block-rounded">
                <div className="block-content p-0">
                    <table className="table table-striped table-vcenter">
                        <thead>
                            <tr>
                                {
                                    props.header && props.header.map((head) => {
                                        return (<th key={head.name} className={head.style ?? ""}>{head.name}</th>)
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>{renderTableBody()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Table
