import { Link, withRouter } from "react-router-dom";

const Sidebar = (props) => {
  
  const routesAvailable = (url) => {
     return props.routes.some((route) => route.path === url);
  }

  return (
    <nav id="sidebar" aria-label="Main Navigation">
      <div className="bg-header-dark">
        <div className="content-header bg-white-10">
          <Link className="font-w600 text-white tracking-wide" to={"/"}>
            <span className="smini-visible">
              F<span className="opacity-75">C</span>
            </span>
            <span className="smini-hidden">
              Flow<span className="opacity-75">Consent</span>
            </span>
          </Link>

          <div>
            <span
              className="d-lg-none text-white ml-2"
              style={{ cursor: "pointer"}}
              data-toggle="layout"
              data-action="sidebar_close"
            >
              <i className="fa fa-times-circle"></i>
            </span>
          </div>
        </div>
      </div>

      <div className="js-sidebar-scroll">
        <div className="content-side">
          <ul className="nav-main">
            
            <li className="nav-main-item">
              <Link className="nav-main-link" to="/">
                <i className="nav-main-link-icon fa fa-location-arrow"></i>
                <span className="nav-main-link-name">Dashboard</span>
              </Link>
            </li>

            {(routesAvailable("/approvals/connoisseur") || routesAvailable("/approvals/received") 
            || routesAvailable("/approvals/sent") ) &&
            <li className="nav-main-heading">Aprovações</li>
            }
            <SidebarItem url="/approvals/connoisseur" icon="fa-inbox" name="Recebidas para conhecimento" routesAvailable={routesAvailable}/>
            <SidebarItem url="/approvals/received" icon="fa-inbox" name="Recebidas para aprovação" routesAvailable={routesAvailable}/>
            <SidebarItem url="/approvals/sent" icon="fa-paper-plane" name="Enviadas" routesAvailable={routesAvailable}/>

            
            
            { routesAvailable("/approvals/templates") &&
            <li className="nav-main-heading">Geral</li>
            }
            <SidebarItem url="/approvals/templates" icon="fa-list" name="Modelos Predefinidos" routesAvailable={routesAvailable}/>
            
            { routesAvailable("/users") &&
            <li className="nav-main-heading">Users</li>
            }
            <SidebarItem url="/users" icon="fa-users" name="Utilizadores" routesAvailable={routesAvailable}/>

          </ul>
        </div>
      </div>
    </nav>
  );
};



function SidebarItem({url,name,icon,routesAvailable}) {
  return (
    <div>
        {
          routesAvailable(url) && 
          <li className="nav-main-item">
            <Link className="nav-main-link" to={url}>
              <i className={`nav-main-link-icon fa ${icon}`}></i>
              <span className="nav-main-link-name">{name}</span>
            </Link>
          </li>
        }
    </div>
  )
}


export default withRouter(Sidebar);
