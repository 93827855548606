import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "store/userSlice";

const Header = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((root) => {
    return root.userReducer;
  })
  const { instance, accounts } = useMsal();

  return (
    <header id="page-header">
      <div className="content-header">
        <div>
          <button
            type="button"
            className="btn btn-dual"
            data-toggle="layout"
            data-action="sidebar_toggle"
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>
        </div>
        <div>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn btn-dual"
              id="page-header-user-dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-fw fa-user d-sm-none"></i>
              <span className="d-none d-sm-inline-block">{user.user && user.user.name}</span>
              <i className="fa fa-fw fa-angle-down ml-1 d-none d-sm-inline-block"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right p-0"
              aria-labelledby="page-header-user-dropdown"
            >
              <div className="bg-primary rounded-top font-w600 text-white text-center p-3">
                Utilizador
              </div>
              <div className="p-2">
                <button type="button"
                  className="dropdown-item"
                  onClick={() => {
                    dispatch(userLogout()).then(x=>{
                      if (accounts.length) {
                        instance.logoutRedirect();
                      }
                    });
                  }}
                >
                  <i className="far fa-fw fa-arrow-alt-circle-left mr-1"></i> Terminar Sessão
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="page-header-loader" className="overlay-header bg-header-dark">
        <div className="bg-white-10">
          <div className="content-header">
            <div className="w-100 text-center">
              <i className="fa fa-fw fa-sun fa-spin text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
