import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, withRouter } from 'react-router-dom';
import { userLogin } from 'store/userSlice';
import App from './App';
import LoginPage from './pages/LoginPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import Page404 from './pages/Page404';

const AppWrapper = () => {

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    const user = useSelector((root) => {
        return root.userReducer;
    });

    if(!user.isLoggedIn)
    {
        switch (location.pathname) {
            case '/404':
                return <Route path="/404" component={Page404} />
            case '/resetPassword': 
                return <ResetPasswordPage />
            default:
                return <LoginPage />
        }
    }

    switch (location.pathname) {
        case '/error':
            return <h1>ERROR</h1>//<Route path="/error" component={Error} />
        case '/404':
            return <Route path="/404" component={Page404} />
        default:
            return <App />;
    }
}

export default withRouter(AppWrapper);
