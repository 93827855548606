import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


export const getUsers = createAsyncThunk("users/getUsers",
    async () => {
        const response = await axios.get(`/user`);

        return await response.data.users ?? null;
    }
);


export const getUserById = createAsyncThunk("users/getUserById",
    async (id) => {
        const response = await axios.get(`/user/${id}`);
        return await response.data.user ?? null;
    }
)

export const draftUser = createAsyncThunk("users/draft",
    async () => {
        return { 
            name : "",
            email : "",
            profile : undefined
        }
    }
)

export const saveUser = createAsyncThunk("user/save",
    async (userData) =>  {
        try {
            const response = !userData.id 
            ? await axios.post(`/user`, userData)
            : await axios.put(`/user/${userData.id}`,userData);
            
            return await response.data;
        } catch (error) {
            throw error
        }
    }
)

export const deleteUser = createAsyncThunk("users/deleteUser",
    async (id) => {
        const resposnse = await axios.delete(`/user/${id}`);
    }
)


export const getAllProfiles = createAsyncThunk( "profile/getAllProfiles" ,
    async() => {
        const response = await axios.get(`/user/getAllProfiles`);
        return await response.data ?? null;
    }
)


const usersSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        user : null,
        profileList: []
    },
    reducers: {
    },
    extraReducers: {
        [getUsers.fulfilled]: (state, action) => { state.users = action.payload },
        [getUsers.rejected]: (state, action) => error(action.error.message),

        [getUserById.fulfilled]: (state, action) => {state.user = action.payload},
        [getUserById.rejected]: (state, action) => error(action.payload.message),

        
        [draftUser.fulfilled]: (state, action) => {state.user = action.payload},
        [draftUser.rejected]: (state, action) => error("Não foi possivel inicar a criação de um utilizador"),
        
        [saveUser.fulfilled]: (state, action) => action.payload,
        [saveUser.rejected]: (state, action) => error(action.payload.message),


        [deleteUser.fulfilled]: (state, action) => { },
        [deleteUser.rejected]: (state, action) => error(action.error.message),

        [getAllProfiles.fulfilled]: (state, action) => { state.profileList = action.payload },
        [getAllProfiles.rejected]: (state, action) => error(action.error.message),
    }
});

export default usersSlice.reducer;


const error = (message) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: message,
        width: "800px",
    })
}