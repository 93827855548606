import {
  useMsal,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, userLoginAzure } from "store/userSlice";
import { loginRequest, AzureEnabled, DemoVersion } from "environmentConfig";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const loginUser = () => {
    dispatch(userLogin({ username, password })).then((e) => {
      console.log(e);
      if (e.payload && e.payload.access_token) {
        window.location.reload();
      }
    });
  };

  const loginUserOffice365 = () => {
    instance.loginPopup(loginRequest).then((e) => {
      if (e.account) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: e.account,
          })
          .then((response) => {
            dispatch(userLoginAzure({ token: response.idToken })).then((e) => {
              if (e.payload && e.payload.access_token) {
                window.location.reload();
              }
            });
          });
      }
    });
  };


  const demoLogin = (username, password) =>{
    setUsername(username)
    setPassword(password)
    dispatch(userLogin({ username, password })).then((e) => {
      console.log(e);
      if (e.payload && e.payload.access_token) {
        window.location.reload();
      }
    });
  }
  

  return (
    <main id="main-container">
      <div className="row no-gutters justify-content-center bg-body-dark">
        <div className="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
          <div
            className="block block-rounded block-transparent block-fx-pop w-100 mb-0 overflow-hidden bg-image"
            style={{
              backgroundImage: `url('/assets/media/photos/photo20@2x.jpg')`,
            }}
          >
            <div className="row no-gutters">
              <div className="col-md-6 order-md-1 bg-white">
                <div className="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
                  <div className="mb-2 text-center">
                    <a className="link-fx font-w700 font-size-h1" href="/">
                      <span className="text-dark">Flow</span>
                      <span className="text-primary">Consent</span>
                    </a>
                    <p className="text-uppercase font-w700 font-size-sm text-muted">
                      Autenticar
                    </p>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-alt"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Endereço de Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-alt"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Palavra-Passe"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-block btn-hero-primary"
                      onClick={() => {
                        loginUser();
                      }}
                    >
                      <i className="fa fa-fw fa-sign-in-alt mr-1"></i>{" "}
                      Autenticar
                    </button>

                    { AzureEnabled &&
                      <button
                        type="button"
                        className="btn btn-block btn-hero-primary"
                        onClick={() => {
                          loginUserOffice365();
                        }}
                      >
                      <i className="fa fa-fw fa-sign-in-alt mr-1"></i>{" "}
                      Autenticar Office 365
                     </button>
                    }

                    { DemoVersion &&
                    <div className="mt-4">

                      <p className="text-uppercase font-w700 font-size-sm text-muted mb-2">
                        Inicar como:
                      </p>

                      <button type="button" className="btn btn-block btn-sm btn-hero btn-secondary"
                        onClick={ () => {
                          demoLogin("demo-admin@emptytroubles.pt", "demo-admin")
                        }}
                      >demo-admin</button>

                      <button type="button"className="btn btn-block btn-sm btn-hero btn-secondary"
                        onClick={ () => {
                          demoLogin("demo-user@emptytroubles.pt", "demo-user")
                        }}
                      >demo-user</button>
                    </div>
                    }

                  </div>
                </div>

                <div className="row text-center">
                  <div className="col-12">
                    <Link to={`/resetPassword`} className="mb-5">
                      Esqueceu-se da sua password?
                    </Link>
                  </div>
                </div>

                {/* <div className="row text-center">
                  <div className="col-12">
                    <img
                      src={`${process.env.PUBLIC_URL}assets/media/logos/emptytroubles.png`}
                      alt=""
                      width="100px"
                    />
                  </div>
                </div> */}
              </div>
              <div className="col-md-6 order-md-0 bg-primary-dark-op d-flex align-items-center">
                <div className="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
                  <div className="media">
                    <div className="media-body">
                      <h2 className="text-white font-w600 mb-1">
                        Plataforma para Pedidos de Aprovação
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
