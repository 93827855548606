import * as React from "react";
import {
  NormalPeoplePicker,
  PeoplePickerItem,
  PeoplePickerItemSuggestion,
} from "office-ui-fabric-react/lib/Pickers";
import axios from "axios";

const suggestionProps = {
  suggestionsHeaderText: "Resultados",
  mostRecentlyUsedHeaderText: "Utilizadores Recentes",
  noResultsFoundText: "Nenhum resultado encontrado",
  loadingText: "A carregar...",
  showRemoveButtons: false,
};

const PeoplePicker = ({handleSelectedItems, selectedItems,exclude=[]}) => {
  const picker = React.useRef(null);

  const onItemsChange = (items) => {
    handleSelectedItems(items);
  };

  const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
    if (filterText) {
      let res = await axios.get(`/user/peoplepicker?search=${filterText}`);
      let filteredPersonas = res.data.users.map((user) => {
        return { user: user };
      });

      filteredPersonas = removeDuplicates(filteredPersonas,exclude)
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filteredPersonas;
    } else {
      return [];
    }
  };

  const onRenderPrimaryText = (persona) => {
    return persona.user.name;
  };

  const onRenderSecondaryText = (persona) => {
    return persona.user.email;
  };

  const onRenderSuggestionsItem = (personaProps, suggestionsProps) => (
    <PeoplePickerItemSuggestion
      personaProps={{
        ...personaProps,
        onRenderPrimaryText: onRenderPrimaryText,
        onRenderSecondaryText: onRenderSecondaryText,
      }}
      suggestionsProps={suggestionsProps}
      styles={{ personaWrapper: { width: "100%" } }}
    />
  );

  const onRenderItemPrimaryText = (e) => {
    return e.user.name;
  }

  const onRenderItemSecondaryText = (e) => {
    return e.user.email;
  }


  const onRenderItem = (pickerItemProps) => (<PeoplePickerItem componentRef={pickerItemProps.componentRef}
    item={{
      ...pickerItemProps.item,
      onRenderPrimaryText: onRenderItemPrimaryText,
      onRenderSecondaryText: onRenderItemSecondaryText
    }} index={pickerItemProps.index} selected={pickerItemProps.selected} onRemoveItem={pickerItemProps.onRemoveItem} onItemChange={pickerItemProps.onItemChange} key={pickerItemProps.key} removeButtonAriaLabel={pickerItemProps.removeButtonAriaLabel} />)

  return (
    <div>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        onRenderItem={onRenderItem}
        className={"ms-PeoplePicker"}
        key={"controlled"}
        selectedItems={selectedItems}
        onChange={onItemsChange}
        componentRef={picker}
        resolveDelay={300}
      />
    </div>
  );
};

function removeDuplicates(personas, possibleDupes) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(persona, personas) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.user.email === persona.user.email).length > 0;
}

function getTextFromItem(persona) {
  return persona.user.name;
}

export default PeoplePicker;
