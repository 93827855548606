import React, { useEffect, useMemo, useState } from "react";
import _ from "@lodash";
import PeoplePicker from "../components/PeoplePicker";
import { SpinButton } from "@fluentui/react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { animateScroll } from "react-scroll";
import { useParams } from "react-router";
import { Link, Redirect } from "react-router-dom";
import withReducer from "store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useForm, useDeepCompareEffect } from "hooks";
import sizeToHuman from "utils/sizeToHuman";
import * as pathToRegexp from "path-to-regexp";
import { useDropzone } from "react-dropzone";
import {
  getApproval,
  newApproval,
  saveApproval,
  resetApproval,
  importApprovalTemplate,
} from "./store/approvalSlice";
import { userHasPermission, PermissionsList } from "config/permissions";

const ApprovalsNewPage = (props) => {
  const dispatch = useDispatch();
  const approval = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalReducer;
  });

  const [noApproval, setNoApproval] = useState(false);
  const { form, handleChange, setForm, setInForm } = useForm(null);
  const routeParams = useParams();

  const changePageToView = (id) => {
    const toPath = pathToRegexp.compile("/approvals/:approvalId/view");
    const newPath = toPath({
      ...routeParams,
      approvalId: "" + id,
    });
    if (newPath !== props.match.url) {
      props.history.push(newPath);
    }
  };

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const LoginUserHasPermission = (param) => {
    if (param === "new") {
      return userHasPermission(LoginUser.profile.permissions, PermissionsList.APPROVAL_CREATE)
    }
    else {
      return userHasPermission(LoginUser.profile.permissions, PermissionsList.APPROVAL_EDIT)
    }
  }

  useDeepCompareEffect(() => {
    function updateApprovalState() {
      const { approvalId } = routeParams;

      if (approvalId === "new") {
        if (routeParams.templateId) {
          dispatch(importApprovalTemplate(routeParams.templateId))
            .then((action) => {
              if (!action.payload) {
                setNoApproval(true);
              }
            });

        } else {
          dispatch(newApproval());
        }
      } else {
        dispatch(getApproval(routeParams.approvalId)).then((action) => {
          if (!action.payload) {
            setNoApproval(true);
          }
        });
      }
    }

    updateApprovalState();
  }, [dispatch, routeParams]);

  useEffect(() => {
    if ((approval && !form) || (approval && form && approval.id !== form.id)) {
      setForm(approval);
    }
  }, [form, approval, setForm]);

  useEffect(() => {
    return () => {
      dispatch(resetApproval());
      setNoApproval(false);
    };
  }, [dispatch]);

  function canBeSubmitted() {
    return form.title.length > 0 && !_.isEqual(approval, form)
      && form.steps.every(checkStepMinimumOptionalApprovers);
  }

  /* Start Attachments Management */

  const dropBaseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const dropActiveStyle = {
    borderColor: "#2196f3",
  };

  const dropAcceptStyle = {
    borderColor: "#00e676",
  };

  const dropRejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (files) => handleUploadChange(files),
  });
  const dropStyle = useMemo(
    () => ({
      ...dropBaseStyle,
      ...(isDragActive ? dropActiveStyle : {}),
      ...(isDragAccept ? dropAcceptStyle : {}),
      ...(isDragReject ? dropRejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );


  function handleUploadChange(e) {
    const files = e;
    if (!files) {
      return;
    }

    let attachments = [...form.attachments];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        attachments.push({
          name: file.name,
          data: `data:${file.type};base64,${btoa(reader.result)}`,
          type: file.type,
          size: file.size,
        });

        attachments = attachments.map((attachment, index) => {
          return { ...attachment, index: index };
        });

        setForm({ ...form, attachments: attachments });
      };

      reader.onerror = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: "error",
          title: "Ocorreu um erro",
          text: "Desculpe mas não conseguimos ler todos os ficheiros!",
          footer:
            '<a href="#">Se o erro persistir contacte o fornecedor da aplicação</a>',
        });
      };
    });
  }
  const removeAttachment = (index) => {
    let attachments = [...form.attachments];

    attachments = attachments
      .slice(0, index)
      .concat(attachments.slice(index + 1));

    attachments = attachments.map((attachment, index) => {
      return { ...attachment, index: index };
    });

    setForm({ ...form, attachments: attachments });
  };

  /* End Attachments Management */

  const handleSelectedConnoisseurs = (items) => {
    setForm({ ...form, connoisseurs: items });
  };

  /* Start Steps Management */
  const addStep = () => {
    let steps = form.steps.map((step) => {
      return { ...step };
    });

    steps.push({
      index: 0,
      name: "",
      approvers: [],
      approversOptional: [],
      neededApprovers: 0,
    });

    steps = steps.map((step, index) => {
      return { ...step, index: index };
    });

    setForm({ ...form, steps: steps });

    animateScroll.scrollToBottom();
  };

  const removeStep = (step, index) => {
    let steps = [...form.steps];

    steps = steps.slice(0, index).concat(steps.slice(index + 1));

    steps = steps.map((step, index) => {
      return { ...step, index: index };
    });

    setForm({ ...form, steps: steps });
  };

  const handleStepNeededApprovers = (e, index) => {
    let steps = form.steps.map((step, i) => {
      if (index === i) {
        if (Number(e) > 100 || Number(e) < 0 || isNaN(+e)) {
          return { ...step, neededApprovers: 0 };
        } else {
          return { ...step, neededApprovers: e };
        }
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, steps: steps });
  };

  const handleStepNameChange = (e, index) => {
    let steps = form.steps.map((step, i) => {
      if (index === i) {
        return { ...step, name: e.target.value };
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, steps: steps });
  };

  /* // End Steps Management // */

  /* Start Approvers Management */

  const handleSelectedMandatoryApprovers = (index, items) => {
    let steps = form.steps.map((step, i) => {
      if (index === i) {
        let approvers = step.approvers.filter((x) => x.mandatory === "0");
        Array.prototype.push.apply(
          approvers,
          items.map((x) => ({ ...x, mandatory: "1" }))
        );
        return { ...step, approvers };
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, steps: steps });
  };

  const handleSelectedApprovers = (index, items) => {
    let steps = form.steps.map((step, i) => {
      if (index === i) {
        let approvers = step.approvers.filter((x) => x.mandatory === "1");
        Array.prototype.push.apply(
          approvers,
          items.map((x) => ({ ...x, mandatory: "0" }))
        );
        return { ...step, approvers };
      } else {
        return { ...step };
      }
    });

    setForm({ ...form, steps: steps });
  };

  const checkStepMinimumOptionalApprovers = (step) => {
    const count = step.approvers.filter(x => x.mandatory == "0").length ?? 0;
    return count >= step.neededApprovers;
  }
  /* // End Approvers Management */

  /* Start Custom Fields */
  const addCustomField = async () => {
    const { value: fieldName } = await Swal.fire({
      title: "Introduza o nome do campo",
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Tem de indicar um nome!";
        }
      },
    });

    if (fieldName) {
      let fields = [...form.fields];

      fields.push({
        name: fieldName,
        value: "",
        index: 0,
      });

      fields = fields.map((field, index) => {
        return { ...field, index: index };
      });

      setForm({ ...form, fields: fields });
    }
  };

  const editCustomField = async (field, index) => {
    const { value: fieldName } = await Swal.fire({
      title: "Introduza o nome do campo",
      input: "text",
      inputValue: field.name,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Tem de indicar um nome!";
        }
      },
    });

    if (fieldName) {
      let fields = form.fields.map((field, i) => {
        if (index === i) {
          return { ...field, name: fieldName };
        } else {
          return { ...field };
        }
      });

      setForm({ ...form, fields: fields });
    }
  };

  const removeCustomField = (field, index) => {
    let fields = [...form.fields];

    fields = fields.slice(0, index).concat(fields.slice(index + 1));

    fields = fields.map((field, index) => {
      return { ...field, index: index };
    });

    setForm({ ...form, fields: fields });
  };

  const handleFieldValueChange = (e, index) => {
    let fields = form.fields.map((field, i) => {
      if (index === i) {
        return { ...field, value: e.target.value };
      } else {
        return { ...field };
      }
    });

    setForm({ ...form, fields: fields });
  };

  /* // End Custom Fields // */


  const isObtainingData = () => {
    return (
      (!approval || (approval && routeParams.approvalId != approval.id))
      && routeParams.approvalId !== "new"
    ) || !form
  }

  /* Render */
  if (noApproval || !LoginUserHasPermission(routeParams.approvalId)) {
    return <Redirect to={"/404"} />;
  }

  if (isObtainingData()) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn sticky-div">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              {form.title ? form.title : "Nova Aprovação"}
            </h1>

            {routeParams.approvalId !== "new" &&
              <button className="btn btn-alt-warning mr-2"
                onClick={() => changePageToView(routeParams.approvalId)}>
                Voltar
              </button>
            }

            <button
              disabled={!canBeSubmitted()}
              className="btn btn-primary"
              onClick={() =>
                dispatch(saveApproval(form)).then((action) => {
                  if (action.payload) {
                    setForm(action.payload);
                    changePageToView(action.payload.id);
                  }
                })
              }
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
      <div className="content animated fadeIn">
        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação básica</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Informação que permite identificar este pedido de aprovação
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <div className="form-group">
                  <label>Título</label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    value={form.title}
                  />
                </div>
                <div className="form-group">
                  <label>Descrição</label>
                  <textarea
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    value={form.description}
                  />
                </div>
                <div className="form-group">
                  <label className="d-block">Anexos</label>
                  <div {...getRootProps({ style: dropStyle })}>
                    <input {...getInputProps()} />
                    <p>Clique aqui ou arraste um ficheiro para adicionar</p>
                  </div>
                </div>
                <div className="row push">
                  {form.attachments.map((x) => {
                    return (
                      <div
                        key={x.index}
                        className="options-container fx-overlay-zoom-out col-xl-4 col-md-6 col-12"
                      >
                        <div className="options-item block block-rounded bg-body mb-0">
                          <div className="block-content text-center">
                            <p className="mb-2 overflow-hidden">
                              <i className="fa fa-fw fa-4x fa-file-alt text-black"></i>
                            </p>
                            <p className="font-w600 mb-0">{x.name}</p>
                            <p className="font-size-sm text-muted">
                              {sizeToHuman(x.size, true)}
                            </p>
                          </div>
                        </div>
                        <div className="options-overlay rounded-lg bg-white-50">
                          <div className="options-overlay-content">
                            <div className="btn-group">
                              {x.id && (
                                <a
                                  className="btn btn-sm btn-dark"
                                  href={axios.defaults.baseURL + x.downloadUrl}
                                >
                                  <i className="fa fa-download text-white mr-1"></i>
                                  Descarregar
                                </a>
                              )}
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  removeAttachment(x.index);
                                }}
                              >
                                <i className="fa fa-trash text-white mr-1"></i>
                                Apagar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação Adicional</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Adicione informação adicional que seja pertinente para a
                  aprovação deste pedido
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                {form.fields.map((field, index) => {
                  return (
                    <div key={`field${index}`} className="form-group">
                      <label>{field.name}</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          onChange={(e) => {
                            handleFieldValueChange(e, index);
                          }}
                          value={field.value}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => editCustomField(field, index)}
                          >
                            <i className="fa fa-fw fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => removeCustomField(field, index)}
                          >
                            <i className="fa fa-fw fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="btn btn-success btn-sm mr-1 mb-3"
                  onClick={(e) => {
                    addCustomField();
                  }}
                >
                  <i className="fa fa-fw fa-plus mr-1"></i> Adicionar Campo
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Conhecimento do Pedido</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Mencione as pessoas que devem ter conhecimento do pedido de
                  aprovação, mas que não tem ação na tomada de decisão
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <PeoplePicker
                  handleSelectedItems={(items) => {
                    handleSelectedConnoisseurs(items);
                  }}
                  selectedItems={form.connoisseurs}
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className="content-heading m-0">Fluxo de Aprovação</h2>
        <div className="row push">
          <div className="col-12">
            <ul className="timeline">
              {form.steps.map((step, index) => {
                return (
                  <li key={`step${index}`} className="timeline-event">
                    <div className="timeline-event-icon bg-gray-dark">
                      <i className="far fa-check"></i>
                    </div>
                    <div className="timeline-event-block block block-rounded">
                      <div className="block-header block-header-default">
                        <h3 className="block-title">
                          <input
                            key={`stepName${index}`}
                            className="form-control"
                            placeholder={`Etapa ${step.index + 1}`}
                            onChange={(e) => {
                              handleStepNameChange(e, index);
                            }}
                            value={step.name}
                          />
                        </h3>
                        <div className="block-options">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => removeStep(step, index)}
                          >
                            <i className="fa fa-fw fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <div className="block-content pb-5">
                        <div className="row">
                          <div className="form-group col-6">
                            <label>Aprovadores obrigatórios</label>
                            <PeoplePicker
                              selectedItems={step.approvers.filter(
                                (x) => x.mandatory === "1"
                              )}
                              index={index}
                              handleSelectedItems={(items) => {
                                handleSelectedMandatoryApprovers(index, items);
                              }}
                              exclude={step.approvers.filter(
                                (x) => x.mandatory === "0"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-6">
                            <label>Aprovadores</label>
                            <PeoplePicker
                              selectedItems={step.approvers.filter(
                                (x) => x.mandatory === "0"
                              )}
                              handleSelectedItems={(items) => {
                                handleSelectedApprovers(index, items);
                              }}
                              exclude={step.approvers.filter(
                                (x) => x.mandatory === "1"
                              )}
                            />
                          </div>
                          <div className="form-group col-6">
                            <label>Aprovadores necessários</label>
                            <SpinButton
                              min={0}
                              max={step.neededApprovers}
                              value={step.neededApprovers}
                              onValidate={(value) =>
                                handleStepNeededApprovers(
                                  isNaN(value) ? 0 : parseInt(value),
                                  index
                                )
                              }
                              onIncrement={() =>
                                handleStepNeededApprovers(
                                  step.neededApprovers + 1,
                                  index
                                )
                              }
                              onDecrement={() =>
                                handleStepNeededApprovers(
                                  step.neededApprovers - 1,
                                  index
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          {!checkStepMinimumOptionalApprovers(step) &&
                            <div className="alert bg-warning d-flex align-items-center flex-fill text-white" role="alert">
                              Selecione mais aprovadores opcionais
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}

              <li className="timeline-event">
                <button
                  type="button"
                  className="btn btn-hero-success mr-1 mb-3"
                  onClick={(e) => {
                    addStep();
                  }}
                >
                  <i className="fa fa-fw fa-plus mr-1"></i> Adicionar Etapa
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      )
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsNewPage);
