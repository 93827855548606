import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "@lodash";
import { getApprovalTemplates } from "./store/approvalTemplatesSlice";
import reducer from "./store";
import withReducer from "store/withReducer";

const ApprovalTemplatesPage = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const approvalTemplates = useSelector(({ approvalTemplatesModule }) => {
    return approvalTemplatesModule.approvalTemplatesReducer.approvalTemplates;
  });

  const searchText = useSelector(({ approvalTemplatesModule }) => {
    return approvalTemplatesModule.approvalTemplatesReducer.searchText;
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(approvalTemplates);

  useEffect(() => {
    dispatch(getApprovalTemplates()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(approvalTemplates, (item) =>
          item.templateTitle.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setData(approvalTemplates);
    }
  }, [approvalTemplates, searchText]);

  if (loading) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (!data || (data && data.length === 0)) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                Modelos de Aprovação
              </h1>
              <nav className="flex-sm-00-auto ml-sm-3">
                <Link to="/approvals/templates/new" className="btn btn-primary">
                  Criar novo modelo
                </Link>
              </nav>
            </div>
          </div>
        </div>
        <div className="content animated fadeIn">
          <h2 className="content-heading">Sem resultados</h2>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                Modelos de Aprovação
              </h1>
              <nav className="flex-sm-00-auto ml-sm-3">
                <Link to="/approvals/templates/new" className="btn btn-primary">
                  Criar novo modelo
                </Link>
              </nav>
            </div>
          </div>
        </div>

        <div className="content animated fadeIn">
          <h2 className="content-heading">Modelos criados por si</h2>

          <div className="row row-deck">
            {data
              .filter((x) => x.user.id === user.user.id)
              .map((x) => {
                return (
                  <div className="col-md-6 col-xl-4">
                    <Link
                      to={`/approvals/templates/${x.id}`}
                      className="block block-rounded block-link-shadow"
                    >
                      <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                        <div>
                          <p className="font-size-lg font-w600 mb-0">
                            {x.templateTitle}
                          </p>
                          <p className="text-muted mb-0">
                            <span className="font-weight-bold">
                              {x.public ? "Público" : "Privado"}
                            </span>
                          </p>
                        </div>
                        <div className="ml-3 item">
                          <Link
                            to={`/approvals/new/template/${x.id}`}
                            className="btn btn-primary"
                          >
                            Usar
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>

          <h2 className="content-heading">
            Modelos partilhados consigo
          </h2>

          <div className="row row-deck">
            {data
              .filter((x) => x.user.id !== user.user.id && x.public === false)
              .map((x) => {
                return (
                  <div className="col-md-6 col-xl-4">
                    <Link
                      to={`/approvals/new/template/${x.id}`}
                      className="block block-rounded block-link-shadow"
                    >
                      <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                        <div>
                          <p className="font-size-lg font-w600 mb-0">
                            {x.templateTitle}
                          </p>
                          <p className="text-muted mb-0">
                            Criado por:{" "}
                            <span className="font-weight-bold">
                              {x.createdBy}
                            </span>
                          </p>
                        </div>
                        <div className="ml-3 item">
                          <Link to={`/approvals/new/template/${x.id}`}
                            className="btn btn-primary"
                          >
                            Usar
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
          
          <h2 className="content-heading">
            Modelos públicos de outros utilizadores
          </h2>

          <div className="row row-deck">
            {data
              .filter((x) => x.user.id !== user.user.id  && x.public === true)
              .map((x) => {
                return (
                  <div className="col-md-6 col-xl-4">
                    <Link
                      to={`/approvals/new/template/${x.id}`}
                      className="block block-rounded block-link-shadow"
                    >
                      <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                        <div>
                          <p className="font-size-lg font-w600 mb-0">
                            {x.templateTitle}
                          </p>
                          <p className="text-muted mb-0">
                            Criado por:{" "}
                            <span className="font-weight-bold">
                              {x.createdBy}
                            </span>
                          </p>
                        </div>
                        <div className="ml-3 item">
                          <Link to={`/approvals/new/template/${x.id}`}
                            className="btn btn-primary"
                          >
                            Usar
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        
        </div>
      </React.Fragment>
    );
  }
};

export default withReducer(
  "approvalTemplatesModule",
  reducer
)(ApprovalTemplatesPage);
