import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getApprovalsNeededAction } from "./approvals/store/approvalsSlice";
import { userHasPermission, PermissionsList } from "config/permissions";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState({});

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const approvalsNeededAction = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvalsNeededAction;
  });

  const [loadingNeededAction, setLoadingNeededAction] = useState(true);
  const [dataNeededAction, setDataNeededAction] = useState(
    approvalsNeededAction
  );

  useEffect(() => {
    axios.get("/reports/dashboard").then((e) => {
      setDashboardData(e.data);
    });
  }, []);

  useEffect(() => {
    dispatch(getApprovalsNeededAction()).then(() =>
      setLoadingNeededAction(false)
    );
  }, [dispatch]);

  useEffect(() => {
    setDataNeededAction(dataNeededAction);
  }, [approvalsNeededAction, dataNeededAction]);

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Bem-vindo, {user.user.name}
            </h1>
            <nav className="flex-sm-00-auto ml-sm-3">
              O que vamos aprovar hoje?
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        {userHasPermission(user.user.profile.permissions, PermissionsList.APPROVAL_CREATE) &&
        <Link to="/approvals/new" className="btn btn-primary mb-4">
          Criar nova aprovação
        </Link>
        }
        <div className="row">
          <div className="col-md-6 col-xl-3">
            <div className="block block-rounded block-link-pop">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                    <p className="text-muted mb-0">Aprovações Recebidas</p>
                    <p className="font-size-h3 mb-0">
                      {dashboardData.approvals_received}
                    </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="block block-rounded block-link-pop">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                    <p className="text-muted mb-0">Aprovações Enviadas</p>
                    <p className="font-size-h3 mb-0">
                      {dashboardData.approvals_sent}
                    </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="block block-rounded block-link-pop">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                    <p className="text-muted mb-0">Aprovações Aprovadas</p>
                    <p className="font-size-h3 mb-0">
                      {dashboardData.approvals_approved}
                    </p>
                </div>
              </div>
            </div>
          </div>
         
          <div className="col-md-6 col-xl-3">
            <div className="block block-rounded block-link-pop">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                    <p className="text-muted mb-0">Aprovações Rejeitadas</p>
                    <p className="font-size-h3 mb-0">
                      {dashboardData.approvals_rejected}
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loadingNeededAction &&
          approvalsNeededAction &&
          approvalsNeededAction.length !== 0 && (
            <React.Fragment>
              <h2 className="content-heading animated fadeIn">
                Necessitam da sua decisão
              </h2>

              <div className="row row-deck animated fadeIn">
                {approvalsNeededAction.map((approval) => {
                  return (
                    <div className="col-md-6 col-xl-4" key={approval.id}>
                      <Link
                        to={`/approvals/${approval.id}/view`}
                        className="block block-rounded block-link-shadow"
                      >
                        <div
                          className="block-content p-0 progress"
                          style={{ height: 2 }}
                        >
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                          <div>
                            <p className="font-size-lg font-w600 mb-0">
                              {approval.title}
                            </p>
                            <p className="text-muted mb-0">
                              Iniciado por:{" "}
                              <span className="font-weight-bold">
                                {approval.createdBy}
                              </span>
                            </p>
                          </div>
                          <div className="ml-3 item">
                            <i className="fa fa-2x fa-clock text-warning"></i>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
