import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getApprovalsReceived = createAsyncThunk(
  "approvals/getApprovals/Received",
  async () => {
    const response = await axios.get("/approval/received");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsSent = createAsyncThunk(
  "approvals/getApprovals/Sent",
  async () => {
    const response = await axios.get("/approval/sent");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsKnowledge = createAsyncThunk(
  "approvals/getApprovals/knowledge",
  async () => {
    const response = await axios.get("/approval/connoisseur");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsNeededAction = createAsyncThunk(
  "approvals/getApprovals/neededAction",
  async () => {
    const response = await axios.get("/approval/received/neededAction");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsNeededKnowledge = createAsyncThunk(
  "approvals/getApprovals/neededKnowledge",
  async () => {
    const response = await axios.get("/approval/connoisseur/neededKnowledge");
    const data = await response.data.approvals;

    return data;
  }
);

const approvalsSlice = createSlice({
  name: "approvals",
  initialState: {
    searchText: "",
    approvals: [],
    approvalsConnoisseur: [],
    appovalsNeededAction: [],
    appovalsNeededKnowledge: [],
  },
  reducers: {
    setApprovalsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getApprovalsReceived.fulfilled]: (state, action) => {
      state.approvals = action.payload;
    },
    [getApprovalsSent.fulfilled]: (state, action) => {
      state.approvals = action.payload;
    },
    [getApprovalsKnowledge.fulfilled]: (state, action) => {
      state.approvalsConnoisseur = action.payload;
    },
    [getApprovalsNeededAction.fulfilled]: (state, action) => {
      state.approvalsNeededAction = action.payload;
    },
    [getApprovalsNeededKnowledge.fulfilled]: (state, action) => {
      state.approvalsNeededKnowledge = action.payload;
    },
  },
});

export const { setApprovalsSearchText } = approvalsSlice.actions;

export default approvalsSlice.reducer;
