import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getApprovalTemplates = createAsyncThunk(
  "approvals/templates/getTemplates",
  async () => {
    const response = await axios.get("/approval-template/");
    const data = await response.data.approvalTemplates;

    return data;
  }
);

const approvalTemplatesSlice = createSlice({
  name: "approvalTemplates",
  initialState: {
    searchText: "",
    approvalTemplates: [],
  },
  reducers: {
    setApprovalTemplatesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getApprovalTemplates.fulfilled]: (state, action) => {
      state.approvalTemplates = action.payload;
    },
  },
});

export const { setApprovalTemplatesSearchText } = approvalTemplatesSlice.actions;

export default approvalTemplatesSlice.reducer;
