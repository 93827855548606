import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AuthService from "services/AuthService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ username, password }) => {
    return await AuthService.login(username, password);
  }
);

export const userLoginAzure = createAsyncThunk(
  "user/login/azure",
  async ({ token }) => {
    return await AuthService.loginAzure(token);
  }
);

export const userLogout = createAsyncThunk("user/logout", async () => {
  AuthService.logout();
  Promise.resolve();
});

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const userSlice = createSlice({
  name: "authModule",
  initialState: initialState,
  extraReducers: {
    [userLogin.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    },
    [userLogin.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Falha no Login",
        width: "800px",
      });
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    },
    [userLoginAzure.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    },
    [userLoginAzure.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Falha no Login",
        text: action.error.message,
        width: "800px",
      });
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    },
    [userLogout.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    },
  },
});

export default userSlice.reducer;
