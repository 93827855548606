import React, { useEffect, useState } from "react";
import { Persona, PersonaSize } from "@fluentui/react";
import { Link } from "react-router-dom";
import _ from "@lodash";
import withReducer from "store/withReducer";
import reducer from "./store";
import moment from "moment";
import "moment/locale/pt";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovalsNeededAction,
  getApprovalsReceived,
  setApprovalsSearchText,
} from "./store/approvalsSlice";
import { userHasPermission, PermissionsList } from "config/permissions";


const ApprovalsReceivedPage = () => {
  const dispatch = useDispatch();
  const approvals = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvals;
  });

  const approvalsNeededAction = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvalsNeededAction;
  });

  const searchText = useSelector(
    ({ approvalsModule }) => approvalsModule.approvalsReducer.searchText
  );

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const [loading, setLoading] = useState(true);
  const [loadingNeededAction, setLoadingNeededAction] = useState(true);
  const [data, setData] = useState(approvals);
  const [dataNeededAction, setDataNeededAction] = useState(approvalsNeededAction);

  useEffect(() => {
    dispatch(getApprovalsReceived()).then(() => setLoading(false));
    dispatch(getApprovalsNeededAction()).then(() => setLoadingNeededAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(approvals, (item) =>
          item.title.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setDataNeededAction(dataNeededAction);
    } else {
      setData(approvals);
      setDataNeededAction(dataNeededAction);
    }
  }, [approvals, approvalsNeededAction, searchText, dataNeededAction]);

  const getStatusBadge = (status) => {
    switch (status) {
      case "DRAFT":
        return <span className="badge badge-secondary">Rascunho</span>;
      case "RUNNING":
        return <span className="badge badge-warning">A Decorrer</span>;
      case "APPROVED":
        return <span className="badge badge-success">Aprovada</span>;
      case "REJECTED":
        return <span className="badge badge-danger">Rejeitada</span>;
      case "CANCELED":
        return <span className="badge badge-danger">Cancelada</span>;
      default:
        return <span className="badge badge-secondary">Other Status</span>;
    }
  };

  const renderTableBody = () => {
    if (loading) {
      return (
        <React.Fragment>
          <div className="bg-body-light animated fadeIn">
            <div className="content content-full">
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                  <i className="fa fa-circle-notch fa-spin text-primary"></i>A
                  obter dados do servidor...
                </h1>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (!data || (data && data.length === 0)) {
      return (
        <React.Fragment>
          <td colSpan={6} className="p-0">
            <div className="bg-body-light animated fadeIn">
              <div className="content content-full">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                  <h4 className="flex-sm-fill font-size-h4 font-w400 mt-2 mb-0 mb-sm-2">
                    Sem resultados
                  </h4>
                </div>
              </div>
            </div>
          </td>
        </React.Fragment>
      );
    } else {
      return data.map((approval) => {
        return (
          <tr key={approval.id}>
            <td className="text-center">
              {approval.id}
            </td>
            <td className="font-w600">
              <Link to={`/approvals/${approval.id}/view`}>
                {approval.title}
              </Link>
            </td>
            <td>{getStatusBadge(approval.status)}</td>
            <td className="text-center">
              {moment(approval.createdAt).calendar()}
            </td>
            <td>
              <Persona text={approval.createdBy} size={PersonaSize.size32} />
            </td>
            <td>
              <Link
                className="btn btn-sm btn-primary"
                to={`/approvals/${approval.id}/view`}
              >
                <i className="fa fa-eye"></i> Ver
              </Link>{" "}
              {approval.status === "DRAFT" && (
                <Link
                  className="btn btn-sm btn-warning"
                  to={`/approvals/${approval.id}`}
                >
                  <i className="fa fa-edit"></i> Editar
                </Link>
              )}
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Aprovações Recebidas
            </h1>
            <nav className="flex-sm-00-auto ml-sm-3">
            {userHasPermission(LoginUser.profile.permissions, PermissionsList.APPROVAL_CREATE) &&
              <Link to="/approvals/new" className="btn btn-primary">
                Criar nova aprovação
              </Link>
            }
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        {!loadingNeededAction && approvalsNeededAction && approvalsNeededAction.length !== 0 && (
          <React.Fragment>
            <h2 className="content-heading animated fadeIn">Necessitam da sua decisão</h2>

            <div className="row row-deck animated fadeIn">
              {approvalsNeededAction.map((approval) => {
                return (
                  <div className="col-md-6 col-xl-4" key={approval.id}>
                    <Link
                      to={`/approvals/${approval.id}/view`}
                      className="block block-rounded block-link-shadow"
                    >
                      <div
                        className="block-content p-0 progress"
                        style={{ height: 2 }}
                      >
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "75%" }}
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                        <div>
                          <p className="font-size-lg font-w600 mb-0">
                            {approval.title}
                          </p>
                          <p className="text-muted mb-0">
                            Iniciado por:{" "}
                            <span className="font-weight-bold">
                              {approval.createdBy}
                            </span>
                          </p>
                        </div>
                        <div className="ml-3 item">
                          <i className="fa fa-2x fa-clock text-warning"></i>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
        <h2 className="content-heading">Todos os pedidos</h2>

        <div className="form-group row items-push mb-0">
          <div className="col-sm-6 col-xl-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white">
                  <i className="fa fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control border-left-0"
                onChange={(e) => {
                  dispatch(setApprovalsSearchText(e));
                }}
                value={searchText}
                placeholder="Procure pelo um título..."
              />
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 offset-xl-6">
            <select className="custom-select">
              <option value="ALL">Todas</option>
              <option value="RUNNING">A decorrer</option>
              <option value="DRAFT">Rascunho</option>
              <option value="APPROVED">Aprovadas</option>
              <option value="REJECTED">Rejeitadas</option>
            </select>
          </div>
        </div>
        <div className="block block-rounded">
          <div className="block-content p-0">
            <table className="table table-striped table-vcenter">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Título</th>
                  <th>Estado</th>
                  <th className="text-center">Criada</th>
                  <th>Pedido por</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsReceivedPage);
