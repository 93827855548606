import axios from 'axios';
import { userLogout } from "store/userSlice";


//interceptor singlton
var interceptPermissionsChanged = (function () {
  var instance;

  async function createInstance(dispatcher,msalinstance,) {
    return axios.interceptors.response.use(
      (next) => {
        return Promise.resolve(next);
      },
      (error) => {  
         if(error?.response?.data){
          const err = error.response.data;
          if(err.statusCode === 409 && err.message === "Permissions changed"){

            if (msalinstance.getAllAccounts().length) {
              msalinstance.logoutRedirect();
            }
             dispatcher(userLogout());
          }
        } 
    
        return Promise.reject(error);
      }
    );
  }

  return {
      getInstance: async function (dispatcher,msalinstance) {
          if (!instance) {
              instance = new Promise(function(resolve, reject) {
                resolve(createInstance(dispatcher,msalinstance))
              });
          }
          return instance;
      }
  };
})();



export {interceptPermissionsChanged};

