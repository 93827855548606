import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "@lodash";
import moment from "moment";
import "moment/locale/pt";

import { deleteUser, getUsers } from "./redux/usersSlice";
import withReducer from "store/withReducer";
import reducer from "./redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { userHasPermission, PermissionsList } from "config/permissions";
import Table from "pages/components/Table";



function UsersPage() {
    const LoginUser = useSelector((root) => {
        return root.userReducer.user;
    });

    const dispatch = useDispatch();

    //const [data, setData] = useState([]);

    const users = useSelector(({ usersModule }) => {
        return usersModule.usersReducer.users ;
    });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(users);
    const [search, setSearch] = useState("");


    const header = [
        { name: "#", style: "text-center" },
        { name: "Nome" },
        { name: "Email" },
        { name: "Perfile" },
        { name: "Criado", style: "text-center" },
        { name: "Ações", style: "text-center" },
    ];

    const renderTableBodyData = () => {
        return data.map((user) => {
            return (
                <tr key={user.id}>
                    <td className="text-center">{user.id}</td>
                    <td>{user.name ?? ""}</td>
                    <td>{user.email ?? ""}</td>
                    <td>{user.profile?.name ?? ""}</td>
                    <td className="text-center">{moment(user.createdAt).calendar()}</td>
                    <td className="text-center">
                        <Link to={`/users/${user.id}/view`} className="btn btn-sm btn-primary">
                            <i className="fa fa-eye"></i> Ver
                        </Link>
                        &nbsp;
                        {userHasPermission(LoginUser.profile.permissions, PermissionsList.USER_EDIT) &&
                        <Link to={`/users/${user.id}`} className="btn btn-sm btn-warning">
                            <i className="fa fa-edit"></i> Editar
                        </Link>
                        }
                        &nbsp;
                        {userHasPermission(LoginUser.profile.permissions, PermissionsList.USER_DELETE) &&
                        <button className="btn btn-sm btn-danger"
                            onClick={() => {
                                confirmDelete(user.id)
                            }}>
                            <i className="fa fa-fw fa-trash"></i> Eliminar
                        </button>
                        }
                    </td>
                </tr>
            )
        })
    }


    const confirmDelete = (userId) => {

        const MySwal = withReactContent(Swal);

        MySwal.fire({
            icon: "info",
            title: "Tem a certeza que pertende eliminar este Utilizador?",
            confirmButtonText: "Eliminar",
            denyButtonText: "Cancelar",
            showDenyButton: true,
            width: "800px",
        })
        .then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteUser(userId))
                .then((res) => {
                    if(!res.error){
                        dispatch(getUsers());
                    }
                })
            }
        });
    }

    useEffect(() => {
        dispatch(getUsers()).then(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (search.length !== 0) {
            setData(
                _.filter(users, (item) => {
                    return item.name.toLowerCase().includes(search.toLowerCase()) ||
                        item.email?.toLowerCase().includes(search.toLowerCase()) ||
                        item.profile?.name.toLowerCase().includes(search.toLowerCase())
                })
            );
        }
        else {
            setData(users);
        }
    }, [users, search])

    /* const fetchData = async () => {

        const response = await axios.get(`/user`);

        const data = await response.data.users;

        data === undefined ? setData([]) : setData(data);
    }


    useEffect(async () => {
        await fetchData() // todo: needs to go to slice
        setLoading(false)
    }, []) */

    return (
        <React.Fragment>
            <div className="bg-body-light animated fadeIn">
                <div className="content content-full">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                        <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                            Utilizadores
                        </h1>
                        <nav className="flex-sm-00-auto ml-sm-3">
                            {userHasPermission(LoginUser.profile.permissions, PermissionsList.USER_CREATE) &&
                                <Link to="/users/new" className="btn btn-primary">
                                    Criar novo utilizador
                                </Link>
                            }
                        </nav>
                    </div>
                </div>
            </div>

            <div className="content animated fadeIn">
                <Table
                    header={header}
                    data={{ data, setData }}
                    loading={{ loading, setLoading }}
                    search={{ search, setSearch }}
                    renderTableBodyData={renderTableBodyData}
                    serachLabel="Procure por um utilizador..." />
            </div>
        </React.Fragment>
    )
}

export default withReducer("usersModule", reducer)(UsersPage)
