import React, { useEffect, useState } from "react";
import { Persona, PersonaSize } from "@fluentui/react";
import { Link } from "react-router-dom";
import _ from "@lodash";
import withReducer from "store/withReducer";
import reducer from "./store";
import moment from "moment";
import "moment/locale/pt";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovalsSent,
  setApprovalsSearchText,
} from "./store/approvalsSlice";
import { userHasPermission, PermissionsList } from "config/permissions";


const ApprovalsSentPage = () => {
  const dispatch = useDispatch();
  const approvals = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvals;
  });
  const searchText = useSelector(
    ({ approvalsModule }) => approvalsModule.approvalsReducer.searchText
  );

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(approvals);

  useEffect(() => {
    dispatch(getApprovalsSent()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(approvals, (item) =>
          item.title.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setData(approvals);
    }
  }, [approvals, searchText]);

  const getStatusBadge = (status) => {
    switch (status) {
      case "DRAFT":
        return <span className="badge badge-secondary">Rascunho</span>;
      case "RUNNING":
        return <span className="badge badge-warning">A Decorrer</span>;
      case "APPROVED":
        return <span className="badge badge-success">Aprovada</span>;
      case "REJECTED":
        return <span className="badge badge-danger">Rejeitada</span>;
      case "CANCELED":
        return <span className="badge badge-danger">Cancelada</span>;
      default:
        return <span className="badge badge-secondary">Outro</span>;
    }
  };

  const renderTableBody = () => {
    if (loading) {
      return (
        <React.Fragment>
          <div className="bg-body-light animated fadeIn">
            <div className="content content-full">
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                  <i className="fa fa-circle-notch fa-spin text-primary"></i>A
                  obter dados do servidor...
                </h1>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (!data || (data && data.length === 0)) {
      return (
        <React.Fragment>
          <td colSpan={5} className="p-0">
            <div className="bg-body-light animated fadeIn">
              <div className="content content-full">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                  <h4 className="flex-sm-fill font-size-h4 font-w400 mt-2 mb-0 mb-sm-2">
                    Sem resultados
                  </h4>
                </div>
              </div>
            </div>
          </td>
        </React.Fragment>
      );
    } else {
      return data.map((approval) => {
        return (
          <tr key={approval.id}>
            <td className="text-center">
              {approval.id}
            </td>
            <td className="font-w600">
              <Link to={`/approvals/${approval.id}/view`}>
                {approval.title}
              </Link>
            </td>
            <td>{getStatusBadge(approval.status)}</td>
            <td className="text-center">
              {moment(approval.createdAt).calendar()}
            </td>
            <td>
              <Persona text={approval.createdBy} size={PersonaSize.size32} />
            </td>
            <td>
            {userHasPermission(LoginUser.profile.permissions, PermissionsList.APPROVAL_VIEW) &&
              <Link
                className="btn btn-sm btn-primary"
                to={`/approvals/${approval.id}/view`}
              >
                <i className="fa fa-eye"></i> Ver
              </Link>
            }
            {" "}

              {approval.status === "DRAFT" && userHasPermission(LoginUser.profile.permissions, PermissionsList.APPROVAL_EDIT) && (
                <Link
                  className="btn btn-sm btn-warning"
                  to={`/approvals/${approval.id}`}
                >
                  <i className="fa fa-edit"></i> Editar
                </Link>
              )}
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Aprovações Enviadas
            </h1>
            <nav className="flex-sm-00-auto ml-sm-3">
            {userHasPermission(LoginUser.profile.permissions, PermissionsList.APPROVAL_CREATE) &&
              <Link to="/approvals/new" className="btn btn-primary">
                Criar nova aprovação
              </Link>
            }
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <div className="form-group row items-push mb-0">
          <div className="col-sm-6 col-xl-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white">
                  <i className="fa fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control border-left-0"
                onChange={(e) => {
                  dispatch(setApprovalsSearchText(e));
                }}
                value={searchText}
                placeholder="Procure pelo um título..."
              />
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 offset-xl-6">
            <select className="custom-select">
              <option value="ALL">Todas</option>
              <option value="RUNNING">A decorrer</option>
              <option value="DRAFT">Rascunho</option>
              <option value="APPROVED">Aprovadas</option>
              <option value="REJECTED">Rejeitadas</option>
            </select>
          </div>
        </div>
        <div className="block block-rounded">
          <div className="block-content p-0">
            <table className="table table-striped table-vcenter">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Título</th>
                  <th>Estado</th>
                  <th className="text-center">Criada</th>
                  <th>Pedido por</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsSentPage);
