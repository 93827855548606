import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useParams } from "react-router-dom";
import _ from "@lodash";
import moment from "moment";
import "moment/locale/pt";

import { getUserById } from "./redux/usersSlice";


import { userHasPermission, PermissionsList } from "config/permissions";


function UsersDetailsPage() {

    const dispatch = useDispatch();

    const LoginUser = useSelector((root) => {
        return root.userReducer.user;
    });

    const user = useSelector(({ usersModule }) => {
        return usersModule.usersReducer.user;
    });

    const [userNotFound, setUserNotFound] = useState(false);
    const routeParams = useParams();



    useEffect(() => {
        const { userId } = routeParams;
        dispatch(getUserById(userId)).then((action) => {
            if (!action.payload) {
                setUserNotFound(true);
            }
        })
    }, []);

    /* useEffect(() => {
        getData();
    }, [dispatch,routeParams]); */



    if (userNotFound) {
        return <Redirect to={"/404"} />
    }

    if (!user || routeParams.userId != user.id.toString()) {
        return (
            <React.Fragment>
                <div className="bg-body-light animated fadeIn">
                    <div className="content content-full">
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                                obter dados do servidor...
                            </h1>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="bg-body-light animated fadeIn">
                <div className="content content-full">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                        <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                            {user.name}
                        </h1>

                        {userHasPermission(LoginUser.profile.permissions, PermissionsList.USER_EDIT) &&
                            <Link to={`/users/${user.id}`} className="btn btn-alt-warning">
                                Editar
                            </Link>
                        }                        

                    </div>
                </div>
            </div>

            <div className="content animated fadeIn">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="block block-rounded">
                            <div className="block-content">
                                <h2 className="content-heading p-0">Informação</h2>

                                <label>Nome</label>
                                <p>{user.name}</p>

                                <label>Email</label>
                                <p>{user.email}</p>

                                <label>Tipo de conta</label>
                                <p>{user.oid ? "Azure" : "Normal"}</p>

                                <label>Criado por</label>
                                <p>{user.createdBy}</p>

                                <label>Criado em</label>
                                <p>{moment(user.createdAt).calendar()}</p>

                            </div>
                        </div>
                    </div>

                    { user.profile && 
                    <div className="col-12 col-lg-6">
                        <div className="block block-rounded">
                            <div className="block-content">
                                <h2 className="content-heading p-0">Perfil</h2>

                                <label>Tipo de conta</label>
                                <p>{user.profile.name}</p>

                                <label>Permissões</label>
                                <p>
                                    {user.profile.permissions && user.profile.permissions.map((perm) => {
                                        return (<span key={perm.id}>{perm.name}<br /></span>);
                                    })}
                                </p>

                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>

        </React.Fragment>
    )
}

export default UsersDetailsPage
