import React, { useEffect, useState } from 'react'

import _ from "@lodash";
import { useParams } from "react-router";
import { Link, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "hooks";
import { draftUser, getAllProfiles, getUserById, saveUser } from "./redux/usersSlice";
import { pathToRegexp } from 'path-to-regexp';

import { userHasPermission, PermissionsList } from "config/permissions";



function UsersNewPage(props) {

    const LoginUser = useSelector((root) => {
        return root.userReducer.user;
    });

    const LoginUserHasPermission = (param) => {
        if(param === "new"){
            return userHasPermission(LoginUser.profile.permissions, PermissionsList.USER_CREATE)
        }
        else{
            return userHasPermission(LoginUser.profile.permissions, PermissionsList.USER_EDIT)
        }
    }

    const dispatch = useDispatch();
    const { user, profileList } = useSelector(({ usersModule }) => {
        return usersModule.usersReducer;
    });


    const { form, handleChange, setForm, setInForm } = useForm(null);
    const [userNotFound, setUserNotFound] = useState(false);
    const routeParams = useParams();


    useEffect(() => {
        const { userId } = routeParams;

        if(userId === "new"){
            dispatch(draftUser());
        }else{
            dispatch(getUserById(userId)).then((action) => {
                if (!action.payload) {
                    setUserNotFound(true);
                }
            });
        }

        dispatch(getAllProfiles())
        
    }, []);

    useEffect(() => {
        if ((user && !form) || (user && form && user.id !== form.id)) {
            setForm(user);
        }

    }, [form, user, setForm]);



    const handlePermissionSelectChange = (e) => {
        const profileId = parseInt(e.target.value);
        if (profileId == null) {
            setForm({ ...form, profile: null })
        }
        const profile = profileList.find((profile) => profile.id == profileId) ?? null;
        setForm({ ...form, profile: profile })
    }

    /* Save Button */
    const saveButtonClick = () => {
       dispatch(saveUser(form))
            .then((action) => {
                if (action.payload && action.payload.id) {
                    //console.log("user updated : ",action.payload);
                    setForm(action.payload);
                    props.history.push(`/users/${action.payload.id}/view`);
                }
            });
    }

    const gerarPassword = () => {
        let collectionOfLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        let size = 10
        let values = new Uint32Array(size);
        window.crypto.getRandomValues(values);
        for(let i=0; i<size; i++){
            result += collectionOfLetters[values[i] % collectionOfLetters.length];
        }
        setForm({ ...form, password: result })
    }


    function canBeSubmitted() {
        return form.name.length > 0 && form.email.length > 0 && !_.isEqual(user, form);
    }

    const isObtainingData = () => {
        return (
            (!user || (user && routeParams.userId != user.id))
            && routeParams.userId !== "new"
        ) || !form
    }



    //render
    if (userNotFound || !LoginUserHasPermission(routeParams.userId)) {
        return <Redirect to={"/404"} />
    }

    if (isObtainingData()) {
        return (
            <React.Fragment>
                <div className="bg-body-light animated fadeIn">
                    <div className="content content-full">
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                                obter dados do servidor...
                            </h1>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="bg-body-light animated fadeIn sticky-div">
                <div className="content content-full">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                        <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                            {user?.name ? user.name : "Novo Utilizador"}
                        </h1>

                        <button style={{ display: !canBeSubmitted() ? 'none' : "inline" }}
                            disabled={!canBeSubmitted()}
                            className="btn btn-primary"
                            onClick={saveButtonClick}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>


            <div className="content animated fadeIn">
                <div className="block block-rounded">
                    <div className="block-content">
                        <h2 className="content-heading pt-0">Informação básica</h2>
                        <div className="row push">
                            <div className="col-lg-4">
                                <p className="text-muted">
                                    Informação básicas do utilizador
                                </p>
                            </div>
                            <div className="col-lg-8 col-xl-5">
                                <div className="form-group">
                                    <label>Nome</label>
                                    <input
                                        className="form-control"
                                        onChange={(e) =>
                                            setForm({ ...form, name: e.target.value })
                                        }
                                        value={form.name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        onChange={(e) =>
                                            setForm({ ...form, email: e.target.value })
                                        }
                                        value={form.email}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            setForm({ ...form, password: e.target.value })
                                        }
                                        value={form.password}
                                    />
                                    <button className="btn btn-secondary form-control mt-1"
                                        onClick={gerarPassword}>
                                        Gerar password
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {profileList && profileList.length !== 0 &&
                    <div className="block block-rounded">
                        <div className="block-content">
                            <h2 className="content-heading pt-0">Perfile de utilização</h2>
                            <div className="row push">
                                <div className="col-lg-4">
                                    <p className="text-muted">
                                        Indique qual o tipo de perfile que o utilizador ira utilizar
                                    </p>
                                </div>
                                <div className="col-lg-8 col-xl-5">

                                    <div className="form-group">
                                        <label>Perfil</label>

                                        <select className="form-control"
                                            value={form.profile?.id ?? undefined}
                                            onChange={(e) => { handlePermissionSelectChange(e) }}>
                                            <option value={undefined}>&nbsp;</option>
                                            {profileList.map((p) => {
                                                return (
                                                    <option key={p.id} value={p.id}>{p.name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    {form.profile?.permissions &&
                                        <div className="form-group">
                                            <label>Permissões </label>
                                            <p>
                                                {form.profile.permissions.map((perm) => {
                                                    return (<span key={perm.id}>{perm.name}<br /></span>);
                                                })}
                                            </p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default UsersNewPage
