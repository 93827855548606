import axios from "axios";
import * as env from "environmentConfig";
import authHeader from "utils/auth-header";

class AuthService {
  login(username, password) {
    return axios
      .post(env.apiUrl + "/auth/login", { username, password })
      .then(async (response) => {
        if (response.data.access_token) {
          const responseUserData = await axios.get(env.apiUrl + "/profile", {
            headers: { Authorization: "Bearer " + response.data.access_token },
          });
          localStorage.setItem(
            "user",
            JSON.stringify({ ...response.data, ...responseUserData.data })
          );
          return { ...response.data, ...responseUserData.data };
        }
        Promise.reject();
      });
  }

  loginAzure(token) {
    return axios
      .post(env.apiUrl + "/auth/login/azure", null, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(async (response) => {
        if (response.data.access_token) {
          const responseUserData = await axios.get(env.apiUrl + "/profile", {
            headers: { Authorization: "Bearer " + response.data.access_token },
          });
          localStorage.setItem(
            "user",
            JSON.stringify({ ...response.data, ...responseUserData.data })
          );
          return { ...response.data, ...responseUserData.data };
        }
        Promise.reject();
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
