import axios from "axios";
import { useDeepCompareEffect } from "hooks";
import {
  DirectionalHint,
  TooltipDelay,
  TooltipHost,
} from "office-ui-fabric-react";
import {
  Persona,
  PersonaPresence,
  PersonaSize,
} from "office-ui-fabric-react/lib/Persona";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Link } from "react-router-dom";
import withReducer from "store/withReducer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import sizeToHuman from "utils/sizeToHuman";
import reducer from "./store";
import {
  acknowledgeApproval,
  getApproval,
  publishApproval,
  resetApproval,
  sendStepResponse,
  cancelApproval,
  deleteApproval
} from "./store/approvalSlice";
import { userHasPermission, PermissionsList } from "config/permissions";
import moment from "moment";

import "moment/locale/pt";

const ApprovalsDetailPage = (props) => {
  const dispatch = useDispatch();
  const approval = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalReducer;
  });

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const [noApproval, setNoApproval] = useState(false);
  const routeParams = useParams();

  useDeepCompareEffect(() => {
    function updateApprovalState() {
      const { approvalId } = routeParams;
      dispatch(getApproval(approvalId)).then((action) => {
        if (!action.payload) {
          setNoApproval(true);
        }
      });
    }

    updateApprovalState();
  }, [dispatch, routeParams]);

  useEffect(() => {
    return () => {
      dispatch(resetApproval());
      setNoApproval(false);
    };
  }, [dispatch]);

  const handleApprovalResponse = async (stepId, response) => {
    if (response) {
      const result = await Swal.fire({
        title: "Aprovar Fluxo",
        text: "Pode adicionar observações à sua resposta",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Aprovar",
      });

      if (result.isConfirmed) {
        dispatch(
          sendStepResponse({
            stepId,
            responseValue: response,
            responseText: result.value,
          })
        ).then(() => {
          dispatch(getApproval(approval.id));
        });
      }
    } else {
      const result = await Swal.fire({
        title: "Rejeitar Fluxo",
        text: "Pode adicionar observações à sua resposta",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Rejeitar",
      });

      if (result.isConfirmed) {
        dispatch(
          sendStepResponse({
            stepId,
            responseValue: response,
            responseText: result.value,
          })
        ).then(() => {
          dispatch(getApproval(approval.id));
        });
      }
    }
  };

  const getStepStatusObject = (status) => {
    switch (status) {
      case "PENDING":
        return {
          title: "Pendente",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fa fa-clock",
        };
      case "RUNNING":
        return {
          title: "A Decorrer",
          backgroundClass: "bg-warning",
          textClass: "text-warning",
          alertClass: "alert-warning",
          icon: "fa fa-spinner fa-spin",
        };
      case "APPROVED":
        return {
          title: "Aprovada",
          backgroundClass: "bg-success",
          textClass: "text-success",
          alertClass: "alert-success",
          icon: "fa fa-check",
        };
      case "REJECTED":
        return {
          title: "Rejeitada",
          backgroundClass: "bg-danger",
          textClass: "text-danger",
          alertClass: "alert-danger",
          icon: "fa fa-times",
        };
      default:
        return {
          title: "Outro",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fa fa-clock",
        };
    }
  };

  const getApprovalStatusObject = (status) => {
    switch (status) {
      case "DRAFT":
        return {
          title: "Rascunho",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "far fa-clock",
        };
      case "RUNNING":
        return {
          title: "A Decorrer",
          backgroundClass: "bg-warning",
          textClass: "text-warning",
          alertClass: "alert-warning",
          icon: "far fa-spinner fa-spin",
        };
      case "APPROVED":
        return {
          title: "Aprovada",
          backgroundClass: "bg-success",
          textClass: "text-success",
          alertClass: "alert-success",
          icon: "fa fa-check",
        };
      case "REJECTED":
        return {
          title: "Rejeitada",
          backgroundClass: "bg-danger",
          textClass: "text-danger",
          alertClass: "alert-danger",
          icon: "fa fa-times",
        };
      case "CANCELED":
        return {
          title: "Cancelado",
          backgroundClass: "bg-danger",
          textClass: "text-danger",
          alertClass: "alert-danger",
          icon: "fa fa-times",
        };
      default:
        return {
          title: "Outro",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fa fa-clock",
        };
    }
  };

  const checkApprovalConcluded = () => {
    return ["APPROVED","REJECTED","CANCELED"].includes(approval.status);
  } 

  const deleteApprovalClick = (id) => {
    const MySwal = withReactContent(Swal);

      MySwal.fire({
          icon: "info",
          title: "Tem a certeza que pertende eliminar esta Aprovação?",
          confirmButtonText: "Eliminar",
          denyButtonText: "Cancelar",
          showDenyButton: true,
          width: "800px",
      })
      .then((result) => {
        if (result.isConfirmed) {
            dispatch(deleteApproval(id))
            .then((res) => {
                if(!res.error){
                  props.history.push(`/approvals/sent`);
                }
            })
        }
    });
  }

  const cancelApprovalClick = (id) => {
    const MySwal = withReactContent(Swal);

      MySwal.fire({
          icon: "info",
          title: "Tem a certeza que pertende eliminar esta Aprovação?",
          confirmButtonText: "Eliminar",
          denyButtonText: "Cancelar",
          showDenyButton: true,
          width: "800px",
      })
      .then((result) => {
          if (result.isConfirmed) {
              dispatch(cancelApproval(id))
              .then(() => {
                dispatch(getApproval(id));
              })
          }
      });
  }

  //renders
  if (noApproval) {
    return <Redirect to={"/404"} />;
  }

  if (
    !approval ||
    (approval && routeParams.approvalId !== approval.id.toString())
  ) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  if (
    approval.status !== "DRAFT" &&
    approval.connoisseurs.some(
      (x) => x.user.id === user.user.id && !x.knowledgeDate
    )
  ) {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Ao abrir esta aprovação estará a tomar conhecimento da mesma",
      confirmButtonText: "Abrir aprovação",
      denyButtonText: "Voltar para trás",
      showDenyButton: true,
      width: "800px",
      backdrop: "rgba(111,111,111,1)",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(acknowledgeApproval(approval.id)).then(() => {
          dispatch(getApproval(approval.id));
        });
      } else if (result.isDismissed || result.isDenied) {
        props.history.goBack();
      }
    });
  }

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              {approval.title}
            </h1>
            {approval.status === "DRAFT" && (
              <React.Fragment>
                {userHasPermission(user.user.profile.permissions, PermissionsList.APPROVAL_EDIT) &&
                  <Link className="btn btn-alt-warning mr-2" to={`/approvals/${approval.id}`}>
                    Editar
                  </Link>
                }

                
                {userHasPermission(user.user.profile.permissions, PermissionsList.APPROVAL_DELETE) &&
                  <button onClick={()=> {deleteApprovalClick(approval.id)}} className="btn btn-alt-danger mr-2">Apagar Fluxo</button>
                }

                <button
                  className="btn btn-danger"
                  onClick={() => {
                    dispatch(publishApproval(approval.id));
                  }}
                >
                  Iniciar Fluxo
                </button>
              </React.Fragment>
            )}


            {approval.status === "RUNNING" && (
              <React.Fragment>
                
                {userHasPermission(user.user.profile.permissions, PermissionsList.APPROVAL_DELETE) &&
                  user.user.name === approval.createdBy && 
                  <button onClick={()=> {cancelApprovalClick(approval.id)}} className="btn btn-alt-danger mr-2">Cancelar Fluxo</button>
                }
              </React.Fragment>
            )}

          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <div
          className={`alert ${
            getApprovalStatusObject(approval.status).backgroundClass
          } d-flex align-items-center`}
          role="alert"
        >
          <div className="flex-00-auto text-white">
            <i
              className={`${getApprovalStatusObject(approval.status).icon}`}
            ></i>
          </div>
          <div className="flex-fill ml-3">
            <h4 className="mb-0 text-white font-weight-bold">
              {getApprovalStatusObject(approval.status).title}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            {approval.connoisseurs.length !== 0 && (
              <div className="block block-rounded block-bordered">
                <div className="block-header block-header-default">
                  <h3 className="block-title">Tomadas de Conhecimento</h3>
                </div>
                <div className="block-content">
                  <Stack horizontal wrap className="pb-3">
                    {approval.connoisseurs.map((connoisseur) => (
                      <TooltipHost
                        tooltipProps={{
                          calloutProps: {
                            styles: () => {
                              if (connoisseur.knowledgeDate === null) {
                                return {
                                  beak: { background: "#ffb119" },
                                  beakCurtain: {
                                    background: "#ffb119",
                                  },
                                  calloutMain: {
                                    background: "#ffb119",
                                  },
                                };
                              } else if (connoisseur.knowledgeDate) {
                                return {
                                  beak: { background: "#82b54b" },
                                  beakCurtain: {
                                    background: "#82b54b",
                                  },
                                  calloutMain: {
                                    background: "#82b54b",
                                  },
                                };
                              }
                            },
                          },
                          onRenderContent: () => {
                            if (connoisseur.knowledgeDate === null) {
                              return (
                                <div
                                  className={"bg-warning"}
                                  style={{
                                    margin: 0,
                                    padding: 10,
                                  }}
                                >
                                  <span className="h5 text-white">
                                    <i className="fa fa-clock mr-1"></i> Por
                                    tomar conhecimento
                                  </span>
                                </div>
                              );
                            } else if (connoisseur.knowledgeDate) {
                              return (
                                <div
                                  className={"bg-success"}
                                  style={{
                                    margin: 0,
                                    padding: 10,
                                  }}
                                >
                                  <span className="h5 text-white">
                                    <i className="fa fa-check mr-1"></i> Tomou
                                    conhecimento
                                  </span>
                                  <span className="d-block text-white">
                                    {moment(connoisseur.knowledgeDate).format(
                                      "LLL"
                                    )}
                                  </span>
                                </div>
                              );
                            }
                          },
                        }}
                        delay={TooltipDelay.zero}
                        directionalHint={DirectionalHint.bottomCenter}
                      >
                        <Persona
                          text={connoisseur.user.name}
                          size={PersonaSize.size32}
                          presence={
                            connoisseur.knowledgeDate !== null
                              ? PersonaPresence.online
                              : PersonaPresence.offline
                          }
                        />
                      </TooltipHost>
                    ))}
                  </Stack>
                </div>
              </div>
            )}
            <div className="block block-rounded">
              <div className="block-content">
                <h2 className="content-heading p-0">Informação</h2>
                <label>Iniciada por</label>
                <p>{approval.createdBy}</p>
                <label>Descrição</label>
                <p>{approval.description}</p>
                {approval.fields.map((field) => {
                  return (
                    <React.Fragment>
                      <label>{field.name}</label>
                      <p>{field.value}</p>
                    </React.Fragment>
                  );
                })}
                <label>Anexos</label>
                <div className="row push">
                  {approval.attachments.map((x) => {
                    return (
                      <div
                        key={x.index}
                        className="options-container fx-overlay-zoom-out col-xl-4 col-md-6 col-12"
                      >
                        <div className="options-item block block-rounded bg-body mb-0">
                          <div className="block-content text-center">
                            <p className="mb-2 overflow-hidden">
                              <i className="fa fa-fw fa-4x fa-file-alt text-black"></i>
                            </p>
                            <p className="font-w600 mb-0">{x.name}</p>
                            <p className="font-size-sm text-muted">
                              {sizeToHuman(x.size, true)}
                            </p>
                          </div>
                        </div>
                        <div className="options-overlay rounded-lg bg-white-50">
                          <div className="options-overlay-content">
                            <div className="btn-group">
                              <a
                                className="btn btn-sm btn-dark"
                                href={axios.defaults.baseURL + x.downloadUrl}
                              >
                                <i className="fa fa-download text-white mr-1"></i>
                                Descarregar
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                  {approval.attachments.length> 0 && checkApprovalConcluded() &&
                    <div className="alert bg-warning d-flex align-items-center flex-fill text-white">
                        Anexos ficam indisponíveis 30 dias após a conclusão
                    </div>
                  }
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <h2 className="content-heading m-0">Fluxo de Aprovação</h2>
            <ul className="timeline">
              {approval.steps.map((step) => {
                let mandatories = step.approvers.filter(
                  // eslint-disable-next-line
                  (x) => x.mandatory == true
                );
                let optionals = step.approvers.filter(
                  // eslint-disable-next-line
                  (x) => x.mandatory == false
                );

                return (
                  <li className="timeline-event">
                    <div
                      className={`timeline-event-icon ${
                        getStepStatusObject(step.status).backgroundClass
                      }`}
                    >
                      <i
                        className={`${getStepStatusObject(step.status).icon}`}
                      ></i>
                    </div>
                    <div className="timeline-event-block block block-rounded">
                      <div
                        className={`block-header block-header-default ${
                          getStepStatusObject(step.status).backgroundClass
                        }`}
                      >
                        <h3 className="block-title text-white">{step.name}</h3>
                        {step.approvers.some((x) => {
                          return (
                            x.user.id === user.user.id &&
                            x.decision === null &&
                            step.status === "RUNNING"
                          );
                        }) && (
                          <div className="block-options">
                            <button
                              type="button"
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                handleApprovalResponse(step.id, 1);
                              }}
                            >
                              Aprovar
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                handleApprovalResponse(step.id, 0);
                              }}
                            >
                              Rejeitar
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="block-content p-3">
                        {mandatories.length !== 0 && (
                          <React.Fragment>
                            <label>Aprovadores</label>
                            <Stack horizontal wrap className="pb-2">
                              {mandatories.map((approver) => (
                                <TooltipHost
                                  tooltipProps={{
                                    calloutProps: {
                                      styles: () => {
                                        if (approver.decision === null) {
                                          return {
                                            beak: { background: "#ffb119" },
                                            beakCurtain: {
                                              background: "#ffb119",
                                            },
                                            calloutMain: {
                                              background: "#ffb119",
                                            },
                                          };
                                        } else if (approver.decision === 1) {
                                          return {
                                            beak: { background: "#82b54b" },
                                            beakCurtain: {
                                              background: "#82b54b",
                                            },
                                            calloutMain: {
                                              background: "#82b54b",
                                            },
                                          };
                                        } else if (approver.decision === 0) {
                                          return {
                                            beak: { background: "#e04f1a" },
                                            beakCurtain: {
                                              background: "#e04f1a",
                                            },
                                            calloutMain: {
                                              background: "#e04f1a",
                                            },
                                          };
                                        }
                                      },
                                    },
                                    onRenderContent: () => {
                                      if (approver.decision === null) {
                                        return (
                                          <div
                                            className={"bg-warning"}
                                            style={{
                                              margin: 0,
                                              padding: 10,
                                            }}
                                          >
                                            <span className="h5 text-white">
                                              <i className="fa fa-clock mr-1"></i>{" "}
                                              Por responder
                                            </span>
                                          </div>
                                        );
                                      } else if (approver.decision === 1) {
                                        return (
                                          <div
                                            className={"bg-success"}
                                            style={{
                                              margin: 0,
                                              padding: 10,
                                            }}
                                          >
                                            <span className="h5 text-white">
                                              <i className="fa fa-check mr-1"></i>{" "}
                                              Aprovou
                                            </span>
                                            <span className="d-block text-white">
                                              {moment(
                                                approver.decisionDate
                                              ).format("LLL")}<br />
                                              {approver.decisionText}
                                            </span>
                                          </div>
                                        );
                                      } else if (approver.decision === 0) {
                                        return (
                                          <div
                                            className={"bg-danger"}
                                            style={{
                                              margin: 0,
                                              padding: 10,
                                            }}
                                          >
                                            <span className="h5 text-white">
                                              <i className="fa fa-times mr-1"></i>{" "}
                                              Rejeitou
                                            </span>
                                            <span className="d-block text-white">
                                              {moment(
                                                approver.decisionDate
                                              ).format("LLL")}<br />
                                              {approver.decisionText}
                                            </span>
                                          </div>
                                        );
                                      }
                                    },
                                  }}
                                  delay={TooltipDelay.zero}
                                  directionalHint={DirectionalHint.bottomCenter}
                                >
                                  <Persona
                                    text={approver.user.name}
                                    size={PersonaSize.size32}
                                    presence={
                                      approver.decision === 1
                                        ? PersonaPresence.online
                                        : approver.decision === 0
                                        ? PersonaPresence.busy
                                        : PersonaPresence.offline
                                    }
                                  />
                                </TooltipHost>
                              ))}
                            </Stack>
                          </React.Fragment>
                        )}

                        {optionals.length !== 0 && (
                          <React.Fragment>
                            <label>Aprovadores Adicionais</label>
                            <Stack horizontal wrap className="pb-2">
                              {optionals.map((approver) => (
                                <TooltipHost
                                  tooltipProps={{
                                    calloutProps: {
                                      styles: () => {
                                        if (approver.decision === null) {
                                          return {
                                            beak: { background: "#ffb119" },
                                            beakCurtain: {
                                              background: "#ffb119",
                                            },
                                            calloutMain: {
                                              background: "#ffb119",
                                            },
                                          };
                                        } else if (approver.decision === 1) {
                                          return {
                                            beak: { background: "#82b54b" },
                                            beakCurtain: {
                                              background: "#82b54b",
                                            },
                                            calloutMain: {
                                              background: "#82b54b",
                                            },
                                          };
                                        } else if (approver.decision === 0) {
                                          return {
                                            beak: { background: "#e04f1a" },
                                            beakCurtain: {
                                              background: "#e04f1a",
                                            },
                                            calloutMain: {
                                              background: "#e04f1a",
                                            },
                                          };
                                        }
                                      },
                                    },
                                    onRenderContent: () => {
                                      if (approver.decision === null) {
                                        return (
                                          <div
                                            className={"bg-warning"}
                                            style={{
                                              margin: 0,
                                              padding: 10,
                                            }}
                                          >
                                            <span className="h5 text-white">
                                              <i className="fa fa-clock mr-1"></i>{" "}
                                              Por responder
                                            </span>
                                          </div>
                                        );
                                      } else if (approver.decision === 1) {
                                        return (
                                          <div
                                            className={"bg-success"}
                                            style={{
                                              margin: 0,
                                              padding: 10,
                                            }}
                                          >
                                            <span className="h5 text-white">
                                              <i className="fa fa-check mr-1"></i>{" "}
                                              Aprovou
                                            </span>
                                            <span className="d-block text-white">
                                            {moment(
                                                approver.decisionDate
                                              ).format("LLL")}<br />
                                              {approver.decisionText}
                                            </span>
                                          </div>
                                        );
                                      } else if (approver.decision === 0) {
                                        return (
                                          <div
                                            className={"bg-danger"}
                                            style={{
                                              margin: 0,
                                              padding: 10,
                                            }}
                                          >
                                            <span className="h5 text-white">
                                              <i className="fa fa-times mr-1"></i>{" "}
                                              Rejeitou
                                            </span>
                                            <span className="d-block text-white">
                                            {moment(
                                                approver.decisionDate
                                              ).format("LLL")}<br />
                                              {approver.decisionText}
                                            </span>
                                          </div>
                                        );
                                      }
                                    },
                                  }}
                                  delay={TooltipDelay.zero}
                                  directionalHint={DirectionalHint.bottomCenter}
                                >
                                  <Persona
                                    text={approver.user.name}
                                    size={PersonaSize.size32}
                                    presence={
                                      approver.decision === 1
                                        ? PersonaPresence.online
                                        : approver.decision === 0
                                        ? PersonaPresence.busy
                                        : PersonaPresence.offline
                                    }
                                  />
                                </TooltipHost>
                              ))}
                            </Stack>
                            <small>
                              São necessárias {step.neededApprovers} aprovações{" "}
                              {mandatories.length !== 0 &&
                                "além das obrigatórias"}
                            </small>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsDetailPage);
