import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice'

const createReducer = asyncReducers =>
	combineReducers({
		...asyncReducers,
		userReducer
	});

export default createReducer;
